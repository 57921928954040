export const contractSignatory = {
  label: "Signataire de la convention & du CERFA",
  code: "signatory",
  models: [
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Signataire du contrat",
        id: "contract_signatory",
        name: "contract_signatory",
        required: true,
        choices: [
          {
            label: "Interlocuteur gestion administrative contrat",
            value: "Interlocuteur gestion administrative contrat",
            data: "Interlocuteur gestion administrative contrat",
          },
        ],
        attr: {
          placeholder: "Sélectionner un signataire",
        },
        row_attr: {
          class: "col-lg-12",
        },
      },
    },
  ],
};
