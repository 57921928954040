export const contractContract = {
  label: "Contrat",
  code: "contract",
  help:
    "<p class='text-info'>Pour information, en cochant la case pour nous donner mandat, nous pouvons transmettre les données relatives au CERFA d'apprentissage et la convention de formation signée directement à votre OPCO, EXCEPTÉ pour l'OPCO SANTE.</p>" +
    "<p>ATTENTION : Si votre alternant suit une formation avec des dates sur 36 mois, merci de bien vouloir faire commencer le contrat, <strong>au plus tôt</strong> à la date de début de formation et de le faire terminer, <strong>au plus tard</strong> à la date de fin de formation.</p>",
  models: [
    {
      vars: {
        block_prefixes: ["", "subtitle"],
        label:
          "Pour information, la formation débutera le 09/09/2024 et se finira le 08/09/2025",
      },
    },
    {
      vars: {
        block_prefixes: ["", "subtitle"],
        label:
          "La date de rentrée du groupe pour la période 2024/2025 est 09/09/2024",
      },
    },
    {
      vars: {
        block_prefixes: ["", "date"],
        label: "Date de début",
        id: "start_date",
        name: "start_date",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "date"],
        label: "Date de fin",
        id: "end_date",
        name: "end_date",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        line_break: true,
        block_prefixes: ["", "date"],
        label: "Date de conclusion (signature)",
        id: "signatory_date",
        name: "signatory_date",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        line_break: true,
        block_prefixes: ["", "choice"],
        label: "Mode contractuel de l’apprentissage",
        id: "contractual_mode",
        name: "contractual_mode",
        required: true,
        choices: [
          {
            label: "à durée limité",
            value: "à durée limité",
            data: "à durée limité",
          },
        ],
        attr: {
          placeholder: "Sélectionner un mode",
        },
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Type de dérogation (optionnel)",
        id: "derogation",
        name: "derogation",
        required: true,
        choices: [
          {
            label: "Allongement de la durée du contrat ou de la...",
            value: "Allongement de la durée du contrat ou de la...",
            data: "Allongement de la durée du contrat ou de la...",
          },
        ],
        attr: {
          placeholder: "Sélectionner un type",
        },
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "switch"],
        label:
          "L’entreprise demande à ce que l’apprenti bénéficie d’un budget d’équipement afin d’obtenir un PC",
      },
    },
    {
      vars: {
        block_prefixes: ["", "switch"],
        label:
          "Travail sur machines dangereuses ou exposition à des risques particuliers (optionnel)",
      },
    },
    {
      vars: {
        block_prefixes: ["", "switch"],
        label:
          "L’employeur atteste disposer de l’ensemble des pièces justificatives nécessaires au dépôt du contrat",
      },
    },
    {
      vars: {
        block_prefixes: ["", "switch"],
        label:
          "Souhaitez-vous donner mandat au CFA pour transmettre : le contrat et la convention finalisés auprès de l’organisme d’enregistrement (OPCO, DREETS) ?",
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Souhaitez-vous que le CFA vous transmette :",
        id: "souhait",
        name: "souhait",
        required: true,
        choices: [
          {
            label: "Le contrat d'apprentissage et la convention de formation",
            value: "Le contrat d'apprentissage et la convention de formation",
            data: "Le contrat d'apprentissage et la convention de formation",
          },
        ],
        attr: {
          placeholder: "Sélectionner une valeur",
        },
        row_attr: {
          class: "col-lg-6",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label:
          "Acceptez-vous la signature numérique pour les documents souhaités ? ",
        id: "electronic_signatory",
        name: "electronic_signatory",
        required: true,
        help: "La signature numérique est un mécanisme qui permet de garantir l'intégrité d'un \n document électronique et d'en authentifier l'auteur, par analogie avec la signature manuscrite \n d'un document papier. Notre prestataire intègre la technologie d’Oodrive_sign et \n Sell&Sign, leaders du cloud computing français et de la gestion des données sensibles.\n Conforme à la réglementation EIDAS qui garantit la valeur probante des documents,\n Oodrive_sign s’appuie sur son Tiers de Confiance CertEurope, qui atteste de la \n validité des signatures en Europe.",
        choices: [
          {
            label: "Oui",
            value: "Oui",
            data: "Oui",
          },
          {
            label: "Non",
            value: "Non",
            data: "Non",
          },
        ],
        attr: {
          placeholder: "Sélectionner une valeur",
        },
        row_attr: {
          class: "col-lg-6",
        },
      },
    },
  ],
};
