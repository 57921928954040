<template>
  <p
    v-if="model && model.vars && model.vars.label"
    class="textStyle"
    v-html="model.vars.label"
  ></p>
</template>

<script>
export default {
  name: "SubTitleTypeComponent",
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isMobile() {
      return window.innerWidth < 992;
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  white-space: pre-wrap;
}
</style>
