<template>
  <div class="contract">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-9">
          <div class="row">
            <div class="col-lg-3">
              <ContractContactBlockComponent />
            </div>

            <div class="col-lg-9">
              <div class="row">
                <div class="col-lg-6">
                  <ContractApprenticeBlockComponent />
                </div>

                <div class="col-lg-6">
                  <ContractCompanyBlockComponent />

                  <ContractTrainingBlockComponent />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <ActionsBlock :actions="actions" />
          <ContractFollowBlockComponent :datas="follow" />
          <ContractCommentBlockComponent />
          <ContractHistoryBlockComponent />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContractContactBlockComponent from "@/components/inscription/contract/ContractContactBlockComponent.vue";
import ContractApprenticeBlockComponent from "@/components/inscription/contract/ContractApprenticeBlockComponent.vue";
import ContractCompanyBlockComponent from "@/components/inscription/contract/ContractCompanyBlockComponent.vue";
import ContractTrainingBlockComponent from "@/components/inscription/contract/ContractTrainingBlockComponent.vue";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import ContractFollowBlockComponent from "@/components/inscription/contract/ContractFollowBlockComponent.vue";
import follow from "@/model/inscription/contract/follow";
import ContractHistoryBlockComponent from "@/components/inscription/contract/ContractHistoryBlockComponent.vue";
import ContractCommentBlockComponent from "@/components/inscription/contract/ContractCommentBlockComponent.vue";

export default {
  name: "ContractSheetView",
  components: {
    ContractCommentBlockComponent,
    ContractHistoryBlockComponent,
    ContractFollowBlockComponent,
    ActionsBlock,
    ContractTrainingBlockComponent,
    ContractCompanyBlockComponent,
    ContractApprenticeBlockComponent,
    ContractContactBlockComponent,
  },
  data() {
    return {
      follow: follow,
      actions: [
        {
          actionType: "button",
          icon: "check ",
          type: "primary",
          action: "registrationValidation",
          translation: "Valider l'inscription",
          cfaStyle: true,
        },
        {
          actionType: "button",
          icon: "triangle-exclamation",
          type: "warning",
          action: "declareEvent",
          translation: "Déclarer un évènement",
          cfaStyle: false,
        },
        {
          actionType: "button",
          icon: "upload",
          type: "danger",
          action: "cancel",
          translation: "Annuler",
          cfaStyle: false,
        },
      ],
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.history {
  font-size: 13px;
}
</style>
