<template>
  <div
    v-if="display()"
    id="breadcrumb"
    :class="'d-lg-block d-none vue-portal-target' + (admin ? '' : ' dashboard')"
  >
    <ul class="navbar-nav flex-row">
      <li>
        <div class="page-header">
          <nav aria-label="breadcrumb" class="breadcrumb-one">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: this.home() }" class="textStyle">
                  <FontAwesomeIcon icon="fa-light fa-house" />
                </router-link>
              </li>
              <li
                v-if="
                  isMultiTriadOrTrainingCourse() &&
                  isDashboard() &&
                  triad &&
                  triad.apprentice
                "
                :class="
                  'breadcrumb-item' +
                  ($route.name === 'Dashboard' ? ' active' : '')
                "
              >
                <router-link
                  v-if="$route.name !== 'Dashboard'"
                  :to="{ name: 'Dashboard' }"
                  class="textStyle"
                >
                  <span>{{
                    triad.apprentice.firstName + " " + triad.apprentice.lastName
                  }}</span>
                </router-link>
                <span v-else>{{
                  triad.apprentice.firstName + " " + triad.apprentice.lastName
                }}</span>
              </li>
              <li
                v-for="(match, index) in breadcrumb.slice(1)"
                :key="match.path"
                :aria-current="
                  Object.keys(breadcrumb).length === index + 1 ? 'page' : ''
                "
                :class="
                  'breadcrumb-item' +
                  (Object.keys(breadcrumb.slice(1)).length === index + 1
                    ? ' active'
                    : '')
                "
              >
                <router-link
                  v-if="index + 1 < Object.keys(breadcrumb.slice(1)).length"
                  :to="{ name: match.path }"
                >
                  <span>{{ match.title }}</span>
                </router-link>
                <div v-else class="d-flex align-items-center">
                  {{ match.title }}
                  <button
                    v-if="match.helpDocuments"
                    class="btn-icon bgStyle"
                    type="button"
                    @click="setShowHelp(true)"
                  >
                    <FontAwesomeIcon
                      v-tooltip="{ title: $t('help') }"
                      class="ms-2 helpDocument"
                      icon="fa-light fa-circle-question"
                    />
                  </button>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { trainingCourseManager } from "@/services/training-course/training-course-manager";
import { stringManager } from "@/services/utilities/string-manager";
import { useMainStore } from "@/store/main/main";
import { useAdminStore } from "@/store/admin/admin";
import { useStudeaManagerStore } from "@/store/studea_manager/studea-manager";
import { useTriadStore } from "@/store/triad/triad";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useNewsStore } from "@/store/news/news";

export default {
  name: "BreadCrumbComponent",
  components: { FontAwesomeIcon },
  props: {
    admin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      alias: null,
      action: "list",
      pageTitle: "",
    };
  },
  computed: {
    ...mapState(useMainStore, {
      breadcrumb: (store) => store.breadcrumb,
      helpDocuments: (store) => store.helpDocuments,
    }),
    ...mapState(useAdminStore, {
      adminMenu: (store) => store.menu,
    }),
    ...mapState(useStudeaManagerStore, {
      studeaManagerMenu: (store) => store.menu,
    }),
    ...mapState(useTriadStore, {
      triads: (store) => store.triads,
      triad: (store) => store.triad,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourses: (store) => store.trainingCourseList,
      trainingCourse: (store) => store.trainingCourse,
    }),
    ...mapState(useNewsStore, {
      newsItem: (store) => store.newsItem,
    }),
  },
  watch: {
    $route() {
      this.initBreadCrumb();
    },

    pageTitle() {
      this.initBreadCrumb();
    },

    triad() {
      if (
        this.triad &&
        this.triad.trainingCourse &&
        this.triad.trainingCourse.menu
      ) {
        this.initBreadCrumb();
      }
    },

    newsItem() {
      this.initBreadCrumb();
    },
  },
  mounted() {
    this.initBreadCrumb();
  },
  updated() {
    this.pageTitle = this.getLocalPageTitle();
  },
  methods: {
    display() {
      let display = true;

      if (
        this.$route.name === "studeaManagerHome" ||
        this.$route.name === "adminHome" ||
        this.$route.name === "TrainingCourseList" ||
        this.$route.name === "TrainingList"
      ) {
        display = false;
      }

      if (
        this.$route.name === "Dashboard" &&
        !this.isMultiTriadOrTrainingCourse()
      ) {
        display = false;
      }
      return display;
    },

    setShowHelp() {
      this.setShowHelpDocuments(true);
    },

    home() {
      if (this.$route.matched.find((item) => item.name === "adminLayout")) {
        return "adminLayout";
      } else if (
        this.$route.matched.find((item) => item.name === "studeaManagerLayout")
      ) {
        return "studeaManagerLayout";
      } else if (this.$route.matched.find((item) => item.name === "Training")) {
        return "TrainingList";
      } else if (
        this.isMultiTriadOrTrainingCourse() ||
        this.$route.name === "trainingCourseDuplicateView"
      ) {
        return "TrainingCourseList";
      } else {
        return "Dashboard";
      }
    },

    isMultiTriadOrTrainingCourse() {
      return (
        (this.triads && Object.keys(this.triads).length > 1) ||
        (this.trainingCourses && Object.keys(this.trainingCourses).length > 1)
      );
    },

    isDashboard() {
      return !!this.$route.matched.find(
        (item) => item.name === "DashboardLayout",
      );
    },

    initBreadCrumb() {
      let matched = this.$route.matched.filter((el) => el.meta.breadcrumb);
      let breadcrumb = [];
      let helpDocuments = null;
      let title = "";
      let pageData = null;
      Object.values(matched).forEach((match) => {
        title = "";
        if (match.meta.generic) {
          let menu;
          let tradPrefix;
          switch (match.meta.generic) {
            case "admin":
              menu = this.adminMenu;
              tradPrefix = "admin";
              break;
            case "studea_manager":
              menu = this.studeaManagerMenu;
              tradPrefix = "studea_manager";
              break;
          }
          if (Object.keys(menu).length) {
            breadcrumb = this.getCurrentMenuBreadCrumb(
              menu,
              breadcrumb,
              match.meta.code ? match.meta.code : null,
              tradPrefix,
            );
          }
        } else {
          if (match.meta.code) {
            title = trainingCourseManager.getPageDatas(match.meta.code);
            if (!helpDocuments) {
              helpDocuments = trainingCourseManager.getPageHelp(
                match.meta.code,
              );
            }
          }
          if (window.localStorage.getItem("studea-pageTitle")) {
            pageData = JSON.parse(
              window.localStorage.getItem("studea-pageTitle"),
            );
            if (pageData.name === match.name) {
              title = pageData.title;
            }
          }
          if (
            match.name === "adminNewsShow" &&
            this.newsItem &&
            this.newsItem.title
          ) {
            title = this.newsItem.title;
          }
          breadcrumb.push({
            title: title ? title : match.meta.title,
            path: match.name,
            helpDocuments: helpDocuments && Object.keys(helpDocuments).length,
          });

          if (helpDocuments && Object.keys(helpDocuments).length) {
            this.setShowHelpDocuments(true);
          }
        }
      });
      this.setBreadcrumb(breadcrumb);
    },

    getCurrentMenuBreadCrumb(menu, breadcrumb, code, tradPrefix) {
      let current = null;
      this.alias = this.$route.params.alias;
      const type = this.$route.path.match("add|edit|list")
        ? this.$route.path.match("add|edit|list")[0]
        : null;
      if (this.alias && type && !code) {
        const gender = stringManager.gender(this.alias);
        Object.values(menu).forEach((item) => {
          if (!current) {
            current = Object.values(item.children.items).find(
              (item) => item.params && item.params.alias === this.alias,
            );
          }
        });
        if (
          type !== "list" &&
          current &&
          current.params &&
          current.params.alias
        ) {
          breadcrumb.push({
            title: this.$tc("global.list.title", gender, {
              name: this.$tc(
                tradPrefix +
                  "." +
                  stringManager.toCamelCase(current.params.alias) +
                  ".title",
                2,
              ),
            }),
            path: current.pathName,
          });
        }

        if (current) {
          breadcrumb.push({
            title: this.$tc("global." + type + ".title", gender, {
              name: this.$tc(
                tradPrefix +
                  "." +
                  stringManager.toCamelCase(current.params.alias) +
                  ".title",
                1,
              ).toLowerCase(),
            }),
            path: current.pathName,
          });
        }
      } else if (code) {
        Object.values(menu).forEach((item) => {
          if (!current) {
            Object.values(item.children.items).forEach((child) => {
              if (!current && child.access === code) {
                current = child;
              }
            });
          }
        });

        if (current) {
          if (
            current.trainingCourseModuleTriad &&
            current.trainingCourseModuleTriad.helpDocument
          ) {
            this.setHelpDocuments(
              current.trainingCourseModuleTriad.helpDocument,
            );
          }

          breadcrumb.push({
            title: current.title,
            path: current.pathName,
            helpDocuments:
              this.helpDocuments && Object.keys(this.helpDocuments).length,
          });
        }
      }
      return breadcrumb;
    },

    getLocalPageTitle() {
      return localStorage.getItem("studea-pageTitle");
    },

    ...mapActions(useMainStore, {
      setShowHelpDocuments: "setShowHelpDocuments",
      setBreadcrumb: "setBreadcrumb",
      setHelpDocuments: "setHelpDocuments",
    }),
  },
};
</script>

<style lang="scss" scoped>
#breadcrumb {
  &.dashboard {
    padding-left: 20px;
  }

  .breadcrumb-item {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

#help-modal {
  z-index: 10000;
}

.helpDocument {
  font-size: 18px;
}
</style>
