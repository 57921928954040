<template>
  <form class="form needs-validation" novalidate="novalidate">
    <div class="grid">
      <div
        v-if="type && type === 'trainingCourse_duplication'"
        class="row w-100"
      >
        <div class="col-12 col-lg-6">
          <template
            v-for="model in formModels.leftColumn.children"
            :key="model.vars.id"
          >
            <div v-if="model.vars.line_break" class="col-12"></div>
            <div
              v-if="
                (!model.vars.attr || !model.vars.attr['data-form-sticky']) &&
                !model.vars.hide
              "
              :class="
                'form-group ' +
                (model.vars.row_attr && model.vars.row_attr.class
                  ? ' ' + model.vars.row_attr.class
                  : '') +
                (model.vars.toggle_class
                  ? ' d-none ' + model.vars.toggle_class
                  : '')
              "
            >
              <ModelComponent
                :disableFocusAnimation="disableFocusAnimation"
                :model="model"
                @ajaxTargetLoad="ajaxTargetLoad"
                @ajaxTargetTrig="ajaxTargetTrig"
                @update="update"
                @toggle="toggle"
                @input="input"
                @uploading="uploading"
                @uploaded="uploaded"
                @dateChanged="dateChanged"
                @resetInputs="resetInputs"
              />
            </div>
          </template>
        </div>
        <div class="col-12 col-lg-6">
          <template
            v-for="model in formModels.rightColumn.children"
            :key="model.vars.id"
          >
            <div v-if="model.vars.line_break" class="col-12"></div>
            <div
              v-if="
                (!model.vars.attr || !model.vars.attr['data-form-sticky']) &&
                !model.vars.hide
              "
              :class="
                'form-group ' +
                (model.vars.row_attr && model.vars.row_attr.class
                  ? ' ' + model.vars.row_attr.class
                  : '') +
                (model.vars.toggle_class
                  ? ' d-none ' + model.vars.toggle_class
                  : '')
              "
            >
              <ModelComponent
                :disableFocusAnimation="disableFocusAnimation"
                :model="model"
                @ajaxTargetLoad="ajaxTargetLoad"
                @ajaxTargetTrig="ajaxTargetTrig"
                @update="update"
                @toggle="toggle"
                @input="input"
                @uploading="uploading"
                @uploaded="uploaded"
                @dateChanged="dateChanged"
                @resetInputs="resetInputs"
              />
            </div>
          </template>
        </div>
      </div>
      <div class="row w-100" v-else>
        <template v-for="model in formModels" :key="model.vars.id">
          <div v-if="model.vars.line_break" class="col-12"></div>
          <div
            v-if="
              (!model.vars.attr || !model.vars.attr['data-form-sticky']) &&
              !model.vars.hide
            "
            :class="
              'form-group ' +
              (model.vars.row_attr && model.vars.row_attr.class
                ? ' ' + model.vars.row_attr.class
                : '') +
              (model.vars.toggle_class
                ? ' d-none ' + model.vars.toggle_class
                : '')
            "
          >
            <div class="d-flex flex-column justify-content-end h-100">
              <ModelComponent
                :disableFocusAnimation="disableFocusAnimation"
                :model="model"
                @ajaxTargetLoad="ajaxTargetLoad"
                @ajaxTargetTrig="ajaxTargetTrig"
                @update="update"
                @toggle="toggle"
                @input="input"
                @uploading="uploading"
                @uploaded="uploaded"
                @dateChanged="dateChanged"
                @resetInputs="resetInputs"
              />
            </div>
          </div>
        </template>
      </div>
      <div v-if="stickyFields.length" class="stickyForm">
        <div class="stickyForm__content">
          <template v-for="model in stickyFields" :key="model.vars.id">
            <div
              v-if="model.vars.attr && model.vars.attr['data-form-sticky']"
              :class="
                'form-group ' +
                (model.vars.row_attr && model.vars.row_attr.class
                  ? ' ' + model.vars.row_attr.class
                  : '') +
                (model.vars.toggle_class
                  ? ' d-none ' + model.vars.toggle_class
                  : '')
              "
            >
              <ModelComponent
                :disableFocusAnimation="disableFocusAnimation"
                :model="model"
                @ajaxTargetLoad="ajaxTargetLoad"
                @ajaxTargetTrig="ajaxTargetTrig"
                @update="update"
                @toggle="toggle"
                @uploading="uploading"
                @uploaded="uploaded"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
    <SubmitTypeComponent
      v-if="
        (!submitModel || (submitModel && submitModel.vars.display)) &&
        !hideSubmit
      "
      :defaultColors="defaultColors"
      :model="submitModel"
      :standAlone="standAlone"
      :submitText="submitText"
      @cancelForm="cancel()"
      @submitForm="submit()"
    />
  </form>
</template>

<script>
import SubmitTypeComponent from "@/components/form/SubmitTypeComponent.vue";
import { formValidator } from "@/services/form/form-validator";
import ModelComponent from "@/components/form/ModelComponent.vue";
import { formManager } from "@/services/form/form-manager";

export default {
  name: "FormComponent",
  components: {
    ModelComponent,
    SubmitTypeComponent,
  },
  data() {
    return {
      errors: [],
      formModels: this.models,
      submitModel: null,
      stickyFields: [],
      fileUploading: false,
      labelChoices: {
        vars: {
          block_prefixes: ["", "choice"],
          label: false,
          expanded: false,
          multiple: true,
          name: "labelChoices",
          value: "",
          required: true,
          id: "labelChoices",
          choices: this.labelChoices,
        },
      },
    };
  },
  props: {
    models: {
      type: [Array, Object],
      required: true,
    },
    disableFocusAnimation: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultColors: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideSubmit: {
      type: Boolean,
      required: false,
      default: false,
    },
    keepHideValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    standAlone: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
    submitText: {
      type: String,
      required: false,
      default: null,
    },
  },
  updated() {
    this.retrieveSubmitModel();
  },
  created() {
    this.retrieveSubmitModel();
  },
  mounted() {
    this.isStickyBlock();
    Object.values(this.models).forEach((model) => {
      this.checkToggleTarget(model);
      this.dateChanged(model);
    });
  },
  methods: {
    submit() {
      if (this.type === "trainingCourse_duplication") {
        const result = {
          ...this.formModels.leftColumn.children,
          ...this.formModels.rightColumn.children,
        };
        this.errors = formValidator.validForm(result);
        if (!this.errors.length && !this.fileUploading) {
          this.$emit("formSubmit", result);
        }
      } else {
        this.errors = formValidator.validForm(this.formModels);
        if (!this.errors.length && !this.fileUploading) {
          this.$emit("formSubmit", this.formModels);
        }
      }
    },
    cancel() {
      this.$emit("formCancel");
    },
    retrieveSubmitModel() {
      let response = null;
      Object.values(this.models).forEach(function (model) {
        if (formManager.isSubmitType(model)) {
          response = model;
        }
      });
      this.submitModel = response;
    },
    update() {
      this.$emit("update");
    },
    input(value) {
      this.$emit("input", value);
    },
    toggle(model) {
      this.$emit("toggle", model);
      this.checkToggleTarget(model);
    },

    uploading() {
      this.fileUploading = true;
    },

    uploaded(data) {
      this.fileUploading = false;
      this.$emit("uploaded", data);
    },

    dateChanged(model) {
      Object.values(this.models).forEach((item) => {
        if (item.children) {
          if (
            !Object.keys(item.children).length ||
            formManager.isDateType(item)
          ) {
            if (
              item.vars.attr &&
              (item.vars.attr["data-day-ref"] ||
                item.vars.attr["data-hour-ref"] ||
                item.vars.attr["data-datehour-ref"])
            ) {
              this.addDateRules(item, model);
            }
          } else {
            Object.values(item.children).forEach((child) => {
              if (
                child.vars.attr &&
                (child.vars.attr["data-day-ref"] ||
                  child.vars.attr["data-hour-ref"] ||
                  item.vars.attr["data-datehour-ref"])
              ) {
                this.addDateRules(child, model);
              }
            });
          }
        }
      });
    },

    addDateRules(model, ref) {
      let minTime = null;
      if (ref.vars.value) {
        if (model.vars.attr["data-day-ref"] === ref.vars.name) {
          minTime = ref.vars.value.split(" ")[0];
        } else if (model.vars.attr["data-hour-ref"] === ref.vars.name) {
          if (formManager.isTimeType(ref)) {
            minTime = ref.vars.value;
          } else {
            minTime = ref.vars.value.split(" ")[1].slice(0, -3);
          }
        } else if (model.vars.attr["data-datehour-ref"] === ref.vars.name) {
          minTime = ref.vars.value;
        }

        if (minTime) {
          model.vars.attr["data-min-date"] = minTime;
          if (minTime > model.vars.value) {
            model.vars.value = minTime;
          }
        }
      }
    },

    checkToggleTarget(model) {
      if (model.vars.toggler_target) {
        let value;
        let targetClass = "";
        if (model.vars.value && model.vars.value.data) {
          value = model.vars.value.data;
          targetClass = "." + model.vars.toggler_target + "-" + value;
        } else if (typeof model.vars.value === "object") {
          value = [];
          let index = 0;
          if (model.vars.value && Object.keys(model.vars.value).length) {
            Object.values(model.vars.value).forEach((choice) => {
              if (choice.checked) {
                targetClass = targetClass.concat(
                  (index ? ", " : "") +
                    "." +
                    model.vars.toggler_target +
                    "-" +
                    choice.value,
                );
                index++;
              }
            });
          }
        } else {
          value = model.vars.value;
          targetClass = "." + model.vars.toggler_target + "-" + value;
        }

        let fieldsToHide = document.querySelectorAll(
          '[class*="' + model.vars.toggler_target + '"]',
        );

        let fieldsToShow;
        if (targetClass) {
          if (
            this.type === "questionnaire-add" &&
            this.models.useCrewDates.vars.value === false &&
            this.models.useSameDates.vars.value === true
          ) {
            fieldsToShow = document.querySelectorAll(
              ".useCrewDates-false:not(.useSameDates-false)",
            );
          } else {
            fieldsToShow = document.querySelectorAll(targetClass);
          }

          for (let i = 0; i < fieldsToHide.length; i++) {
            fieldsToHide[i].classList.add("d-none");
          }
          for (let i = 0; i < fieldsToShow.length; i++) {
            fieldsToShow[i].classList.remove("d-none");
          }
          fieldsToShow = Object.values(this.models).filter(
            (item) =>
              item.vars.toggle_class &&
              item.vars.toggle_class.includes(
                model.vars.toggler_target + "-" + model.vars.value,
              ),
          );

          fieldsToShow.forEach((field) => {
            field.vars.ignoreValidation = false;
          });

          fieldsToHide = Object.values(this.models).filter(
            (item) =>
              item.vars.toggle_class &&
              item.vars.toggle_class.includes(model.vars.toggler_target) &&
              !item.vars.toggle_class.includes(
                model.vars.toggler_target + "-" + model.vars.value,
              ),
          );
          fieldsToHide.forEach((field) => {
            if (!this.keepHideValue) {
              if (formManager.isPhoneType(field)) {
                field.vars.value = "";
              } else {
                field.vars.value =
                  typeof field.vars.value === "boolean" ? false : null;
              }
            }
            field.vars.ignoreValidation = true;
          });
        }
      }
    },
    ajaxTargetLoad(payload) {
      if (this.type === "trainingCourse_duplication") {
        if (this.models.leftColumn.children[payload.target]) {
          this.formModels.leftColumn.children[payload.target].vars.choices =
            payload.choices;
          this.formModels.leftColumn.children[payload.target].vars.value =
            Object.values(payload.choices).find(
              (choice) =>
                choice.value.toString() ===
                this.formModels.leftColumn.children[payload.target].vars.value,
            );
        }
      } else {
        if (this.models[payload.target]) {
          this.formModels[payload.target].vars.choices = payload.choices;

          if (
            Object.values(payload.choices).find(
              (choice) =>
                choice.value.toString() ===
                this.formModels[payload.target].vars.value,
            )
          ) {
            this.formModels[payload.target].vars.value = Object.values(
              payload.choices,
            ).find(
              (choice) =>
                choice.value.toString() ===
                this.formModels[payload.target].vars.value,
            );
          }
        }
      }
    },

    isStickyBlock() {
      this.stickyFields = Object.values(this.models).filter(
        (model) => model.vars.attr && model.vars.attr["data-form-sticky"],
      );
    },

    ajaxTargetTrig(payload) {
      let prefix = "";
      if (
        payload.trigger.vars.attr &&
        payload.trigger.vars.attr["data-ajax-linked-to"]
      ) {
        const ref = Object.values(this.models).find(
          (item) =>
            item.vars.name === payload.trigger.vars.attr["data-ajax-linked-to"],
        );
        if (ref && ref.vars.value.data.id) {
          prefix = ref.vars.value.data.id + "/";
        }

        formManager
          .fieldLoad(payload.trigger, null, null, prefix)
          .then((data) => {
            this.ajaxTargetLoad({
              choices: { ...data },
              target: Object.keys(
                payload.trigger.vars.attr["data-ajax-load"],
              )[0],
            });
          });
      }
    },

    resetInputs(payload) {
      if (payload && payload.item) {
        Object.entries(payload.item).forEach((item) => {
          let target;
          if (this.type === "trainingCourse_duplication") {
            target = this.formModels.leftColumn.children[item[0]];
          } else {
            target = this.formModels[item[0]];
          }
          this.resetChoices(target);
        });
      }
    },

    resetChoices(target) {
      if (this.type === "trainingCourse_duplication") {
        if (target.vars.attr["data-ajax-load"]) {
          const children =
            this.formModels.leftColumn.children[
              Object.keys(target.vars.attr["data-ajax-load"])
            ];
          this.formModels.leftColumn.children[target.vars.name].vars.choices =
            [];
          this.formModels.leftColumn.children[target.vars.name].vars.value =
            undefined;

          this.resetChoices(children);
        } else {
          this.formModels.leftColumn.children[target.vars.name].vars.choices =
            [];
          this.formModels.leftColumn.children[target.vars.name].vars.value =
            undefined;
        }
      } else {
        if (target.vars.attr["data-ajax-load"]) {
          const children =
            this.formModels[Object.keys(target.vars.attr["data-ajax-load"])];
          this.formModels[target.vars.name].vars.choices = [];
          this.formModels[target.vars.name].vars.value = undefined;

          this.resetChoices(children);
        } else {
          this.formModels[target.vars.name].vars.choices = [];
          this.formModels[target.vars.name].vars.value = undefined;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.non-empty {
  overflow: auto;
}

.grid {
  display: flex;

  .row {
    flex: 1;
  }
}

.stickyForm {
  min-width: 500px;
  flex: 0;

  &__content {
    z-index: 10000;
    border: 1px dashed $m-color_4;
    margin-left: 45px;
    padding: 20px;
    height: auto;
    border-radius: 6px;
    position: sticky;
    top: 135px;
  }
}
</style>
