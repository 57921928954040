<template>
  <div class="widget">
    <div class="widget-heading align-items-center">
      <div>
        <h2 class="textStyle h-auto">Commentaires</h2>
      </div>
      <button type="button" class="btn-icon">
        <FontAwesomeIcon icon="fa-light fa-plus" />
      </button>
    </div>
    <div class="widget-content">
      <div class="item">
        <span class="fw-bold">Entreprise : </span>06/07/2023 à 15h11 par Pierre
        DURAND
        <br />
        Merci de changer les dates de contrat au 01/01/2022
      </div>
      <div class="item">
        <span class="fw-bold">Alternant :</span>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "ContractCommentBlockComponent",
  components: { FontAwesomeIcon },
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.item {
  border-bottom: 1px dashed $m_color_5;
  margin-bottom: 2px;
  padding-bottom: 2px;
  font-size: 13px;

  &:last-child {
    border: none;
  }
}
</style>
