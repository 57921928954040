<template>
  <div class="d-flex align-items-center justify-content-center">
    <button type="button" class="btn btn-success btnStyle mx-1">
      <FontAwesomeIcon icon="fa-light fa-file-download" class="me-2" />
      Planning
    </button>
    <button type="button" class="btn btn-success btnStyle mx-1">
      <FontAwesomeIcon icon="fa-light fa-file-download" class="me-2" />
      Fiche RNCP
    </button>
    <button type="button" class="btn btn-success btnStyle mx-1">
      <FontAwesomeIcon icon="fa-light fa-file-download" class="me-2" />
      Programme
    </button>
    <button type="button" class="btn btn-success btnStyle mx-1">
      <FontAwesomeIcon icon="fa-light fa-file-download" class="me-2" />
      Devis
    </button>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "InscriptionDocumentsComponent",
  components: { FontAwesomeIcon },
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
