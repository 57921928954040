import { apprenticePersonnalInformations } from "@/model/inscription/1-apprentice/1.1-personnal-informations";
import { apprenticePreviousSituation } from "@/model/inscription/1-apprentice/1.2-previous-situation";
import { apprenticeHostCompany } from "@/model/inscription/1-apprentice/1.3-host-company";
import { apprenticeDocuments } from "@/model/inscription/1-apprentice/1.4-documents";
import { companyCompanyInformations } from "@/model/inscription/2-company/2.1-company-informations";
import { companyHeadOffice } from "@/model/inscription/2-company/2.2-head-office";
import { companyContacts } from "@/model/inscription/2-company/2.3-contacts";
import { companyContractRegistrationBody } from "@/model/inscription/2-company/2.4-contract-registration-body";
import { missionsMissions } from "@/model/inscription/3-missions/3.1-missions";
import { contractContract } from "@/model/inscription/4-contract/4.1-contract";
import { contractMap } from "@/model/inscription/4-contract/4.2-map";
import { contractSecondMap } from "@/model/inscription/4-contract/4.3-second-map";
import { contractSignatory } from "@/model/inscription/4-contract/4.4-signatory";
import { compensationCompensation } from "@/model/inscription/5-compensation/5.1-compensation";
import { quoteQuote } from "@/model/inscription/6-quote/6.1-quote";
import { quoteDocuments } from "@/model/inscription/6-quote/6.2-documents";

export default [
  {
    label: "Alternant",
    code: "apprentice",
    icon: "user",
    valid: true,
    disabled: false,
    blocks: [
      apprenticePersonnalInformations,
      apprenticePreviousSituation,
      apprenticeHostCompany,
      apprenticeDocuments,
    ],
  },
  {
    label: "Entreprise",
    code: "company",
    icon: "building",
    valid: false,
    progress: true,
    disabled: false,
    blocks: [
      companyCompanyInformations,
      companyHeadOffice,
      companyContacts,
      companyContractRegistrationBody,
    ],
  },
  {
    label: "Missions",
    code: "missions",
    icon: "bullseye",
    valid: false,
    disabled: true,
    blocks: [missionsMissions],
  },
  {
    label: "Contrat",
    icon: "file-contract",
    code: "contract",
    valid: false,
    disabled: false,
    blocks: [
      contractContract,
      contractMap,
      contractSecondMap,
      contractSignatory,
    ],
  },
  {
    label: "Rémunération",
    code: "remuneration",
    icon: "money-bill",
    valid: false,
    disabled: true,
    blocks: [compensationCompensation],
  },
  {
    label: "Devis",
    code: "devis",
    icon: "file-lines",
    valid: false,
    disabled: true,
    blocks: [quoteQuote, quoteDocuments],
  },
  {
    label: "Documents",
    code: "documents",
    icon: "file",
    valid: false,
    disabled: true,
    blocks: [],
  },
];
