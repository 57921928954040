<template>
  <div class="widget">
    <div class="widget-content">
      <ul class="nav nav-pills steps" id="icon-pills-tab" role="tablist">
        <template v-for="(step, index) in steps" :key="step.code">
          <div class="separator" v-if="index">
            <div></div>
          </div>
          <InscriptionStepComponent :index="index" :step="step" />
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import InscriptionStepComponent from "@/components/inscription/InscriptionStepComponent.vue";

export default {
  name: "InscriptionStepsComponent",
  components: { InscriptionStepComponent },
  props: {
    steps: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {},
  },
};
</script>

<style lang="scss" scoped>
.steps {
  justify-content: space-between;
}

.separator {
  flex: 0.5;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    border: 1px dashed $m_color_4;
    height: 1px;
    width: 100%;
  }
}
</style>
