export const apprenticeDocuments = {
  label: "Documents à joindre",
  code: "documents",
  models: [
    {
      vars: {
        block_prefixes: ["", "file"],
        label: "Curriculum Vitae",
      },
    },
  ],
};
