<template>
  <div class="widget">
    <div class="widget-heading align-items-center">
      <div>
        <h2 class="textStyle h-auto">Entreprise</h2>
        <p class="mb-0">BOLLE BRANDS</p>
      </div>
      <router-link
        :to="{ name: 'TrainingPreInscription', params: { step: 2 } }"
        class="btn-icon"
      >
        <FontAwesomeIcon icon="fa-light fa-pen" />
      </router-link>
    </div>
    <div class="widget-content">
      <div class="row">
        <div class="col-lg-6">
          <div class="item">
            <span class="fw-bold">Raison sociale :</span> BOLLE BRANDS
          </div>
          <div class="item">
            <span class="fw-bold">Adresse :</span> 34 rue de la Soie 69100
            VILLEURBANNE
          </div>
          <div class="item">
            <span class="fw-bold">Code activité (NAF) :</span> 4643Z
          </div>
          <div class="item">
            <span class="fw-bold">Effectif total :</span> 145
          </div>
          <div class="item">
            <span class="fw-bold">SIRET :</span> 41336810100056
          </div>
          <div class="item">
            <span class="fw-bold">Type d'employeur :</span> Privé
          </div>
          <div class="item">
            <span class="fw-bold">Sous-type d'employeur :</span> 12
          </div>
        </div>

        <div class="col-lg-6">
          <div class="item">
            <span class="fw-bold">IDCC :</span><br />0573-Convention collective
            nationale des commerçes de gros
          </div>
          <div class="item"><span class="fw-bold">OPCO :</span> AKTO</div>
          <div class="item">
            <span class="fw-bold">Téléphone :</span> 06 02 10 37 41
          </div>
          <div class="item">
            <span class="fw-bold">Email :</span> hr-service@bollebrands.com
          </div>
          <div class="item">
            <span class="fw-bold">Fonction :</span> VP RH & ESG
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContractCompanyBlockComponent",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.item {
  border-bottom: 1px dashed $m_color_5;
  margin-bottom: 2px;
  padding-bottom: 2px;
  font-size: 13px;

  &:last-child {
    border: none;
  }
}
</style>
