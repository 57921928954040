<template>
  <li class="nav-item step">
    <router-link
      :to="{ name: 'TrainingPreInscription', params: { step: stepNumber } }"
      type="button"
      class="nav-link step__link"
      :class="{
        active: current === stepNumber,
        isValid: step.valid,
        isProgress: step.progress,
      }"
    >
      <FontAwesomeIcon :icon="'fa-light fa-' + step.icon" class="mb-2" />
      <span>{{ step.label }}</span>
    </router-link>
  </li>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "InscriptionStepComponent",
  components: { FontAwesomeIcon },
  props: {
    step: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      current: parseInt(this.$route.params.step),
      stepNumber: null,
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.setStepNumber();
    },

    setStepNumber() {
      this.stepNumber = this.index + 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.step {
  display: flex;
  align-items: center;
  flex: 3;

  &__link {
    width: 100%;
    border: 3px solid #f1f2f3 !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0 5px 10px;
    border-radius: 25px !important;

    &.active {
      border-color: var(--inscription-waiting) !important;
    }

    &.isValid {
      border-color: var(--inscription-success) !important;
      color: var(--inscription-success) !important;

      &.active {
        background: var(--inscription-success) !important;
      }
    }

    &.isProgress {
      border-color: var(--inscription-progress) !important;
      color: var(--inscription-progress) !important;

      &.active {
        background: var(--inscription-progress) !important;
      }
    }
  }

  &__icon {
    margin-left: 10px;
    font-size: 20px;
  }

  &__chevron {
    color: $m-color_4;
    margin-left: 15px;
    font-size: 20px;
  }
}
</style>
