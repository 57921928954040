<template>
  <div class="widget">
    <div class="widget-content d-flex justify-content-between">
      <router-link
        :to="{ name: 'TrainingPreInscription', params: { step: prev() } }"
        :class="{ disabled: prev() <= 0 }"
        class="btn blockStyle btn-success"
      >
        <FontAwesomeIcon icon="fa-light fa-chevron-left" />
        Précédent
      </router-link>
      <button class="btn btn-success btnStyle">
        <FontAwesomeIcon icon="fa-light fa-save" class="me-2" />
        Enregistrer
      </button>
      <router-link
        :to="{ name: 'TrainingPreInscription', params: { step: next() } }"
        class="btn btn-success blockStyle"
        :class="{ disabled: next() > Object.keys(this.steps).length }"
      >
        Suivant
        <FontAwesomeIcon icon="fa-light fa-chevron-right" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "InscriptionControlsComponent",
  components: {},
  props: {
    steps: {
      type: Array,
      required: true,
    },
    current: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    next() {
      return this.current + 1;
    },
    prev() {
      return this.current - 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.quote {
  h6 {
    margin: 15px 0;
  }

  &__part {
    border-bottom: 1px dashed $m_color_6;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
}
</style>
