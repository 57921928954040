export const apprenticeHostCompany = {
  label: "Entreprise d'accueil",
  code: "company",
  models: [
    {
      vars: {
        block_prefixes: ["", "switch"],
        label: "J'ai trouvé une entreprise d'accueil",
      },
    },
  ],
};
