export const companyHeadOffice = {
  label: "Siège social",
  code: "head_office",
  models: [
    {
      vars: {
        block_prefixes: ["", "switch"],
        label:
          "Le siège social est différent de l'établissement d'exécution du contrat",
        id: "same_address_head_office",
        name: "same_address_head_office",
        value: true,
        toggler_target: "sameAddress",
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Numéro SIRET",
        id: "siret",
        name: "siret",
        help: "Le siret doit correspondre à l'adresse du lieu d'exécution du contrat",
        required: true,
        toggle_class: "sameAddress-false",
        row_attr: {
          class: "col-lg-6",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Raison sociale",
        id: "social_reason",
        name: "social_reason",
        toggle_class: "sameAddress-false",
        required: true,
        row_attr: {
          class: "col-lg-6",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Code NAF",
        id: "naf_code",
        name: "naf_code",
        toggle_class: "sameAddress-false",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Caisse retraite complémentaire",
        id: "supplementary_pension_fund",
        name: "supplementary_pension_fund",
        toggle_class: "sameAddress-false",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Effectif total",
        id: "total_workforce",
        name: "total_workforce",
        toggle_class: "sameAddress-false",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "title"],
        label: "Adresse",
        toggle_class: "sameAddress-false",
        separator: true,
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Adresse",
        id: "company_address",
        toggle_class: "sameAddress-false",
        name: "company_address",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Complément",
        id: "company_complement",
        toggle_class: "sameAddress-false",
        name: "company_complement",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Commune",
        id: "city",
        toggle_class: "sameAddress-false",
        name: "city",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "title"],
        label: "Dirigeant.e ou DRH",
        separator: true,
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Genre",
        id: "gender",
        name: "gender",
        required: true,
        choices: [
          {
            label: "Mme",
            value: "Mme",
            data: "Mme",
          },
          {
            label: "M.",
            value: "M.",
            data: "M.",
          },
        ],
        attr: {
          placeholder: "Sélectionner un genre",
        },
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Nom",
        id: "lastName",
        name: "lastName",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Prénom",
        id: "firstName",
        name: "firstName",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Fonction",
        id: "function",
        name: "function",
        required: true,
        row_attr: {
          class: "col-lg-6",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "email"],
        label: "E-mail",
        id: "email",
        name: "email",
        required: true,
        row_attr: {
          class: "col-lg-6",
        },
      },
    },
  ],
};
