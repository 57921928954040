<template>
  <div class="widget">
    <div class="widget-heading align-items-center">
      <div>
        <h2 class="textStyle h-auto">Formation</h2>
      </div>
      <button class="btn-icon">
        <FontAwesomeIcon icon="fa-light fa-pen" />
      </button>
    </div>
    <div class="widget-content">
      <div class="row">
        <div class="col-lg-6">
          <div class="item">
            <span class="fw-bold">Date de début :</span>
            12/09/2022
          </div>

          <div class="item">
            <span class="fw-bold">Établissement / Composante :</span><br />
            Jean Moulin Lyon 3 / IAE - LYON 3
          </div>

          <div class="item">
            <span class="fw-bold">Nom de la formation :</span><br />
            Master Gestion des Ressources Humaines - Management des Ressources
            Humaines et Organisation (RHO)
          </div>

          <div class="item">
            <span class="fw-bold">Promotion :</span>
            2022-2024
          </div>

          <div class="item">
            <span class="fw-bold">Groupe :</span>
            Groupe 1
          </div>
        </div>

        <div class="col-lg-6">
          <div class="item">
            <span class="fw-bold">Date de fin :</span> 06/09/2024
          </div>

          <div class="item">
            <span class="fw-bold">SIRET :</span> 4554235200021
          </div>

          <div class="item">
            <span class="fw-bold">Code UAI :</span> 1696516M
          </div>

          <div class="item">
            <span class="fw-bold">Code RNCP :</span> RNCP35912
          </div>

          <div class="item">
            <span class="fw-bold">Code formation :</span> 13531550
          </div>

          <div class="item">
            <span class="fw-bold">Nombre d'heures :</span> 930 heures
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContractTrainingBlockComponent",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.item {
  border-bottom: 1px dashed $m_color_5;
  margin-bottom: 2px;
  padding-bottom: 2px;
  font-size: 13px;

  &:last-child {
    border: none;
  }
}
</style>
