export const companyContacts = {
  label: "Contacts",
  code: "contacts",
  models: [
    {
      vars: {
        block_prefixes: ["", "title"],
        label: "Dirigeant.e ou DRH",
        separator: false,
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Genre",
        id: "gender",
        name: "gender",
        required: true,
        choices: [
          {
            label: "Mme",
            value: "Mme",
            data: "Mme",
          },
          {
            label: "M.",
            value: "M.",
            data: "M.",
          },
        ],
        attr: {
          placeholder: "Sélectionner un genre",
        },
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Nom",
        id: "lastName",
        name: "lastName",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Prénom",
        id: "firstName",
        name: "firstName",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Fonction",
        id: "function",
        name: "function",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "email"],
        label: "E-mail",
        id: "email",
        name: "email",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "phone_number"],
        label: "Téléphone",
        id: "phone",
        name: "phone",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "title"],
        label: "Interlocuteur.rice pour la gestion administrative du contrat",
        help: "Cet interlocuteur.rice recevra les documents Cerfa et convention",
        separator: true,
      },
    },
    {
      vars: {
        block_prefixes: ["", "switch"],
        label: "L'interlocuteur.rice RH est identique au dirigeant.e",
        id: "same_drh",
        name: "same_drh",
        toggler_target: "sameDrh",
        value: false,
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Genre",
        id: "gender",
        name: "gender",
        toggle_class: "sameDrh-false",
        required: true,
        choices: [
          {
            label: "Mme",
            value: "Mme",
            data: "Mme",
          },
          {
            label: "M.",
            value: "M.",
            data: "M.",
          },
        ],
        attr: {
          placeholder: "Sélectionner un genre",
        },
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Nom",
        id: "lastName",
        name: "lastName",
        toggle_class: "sameDrh-false",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Prénom",
        id: "firstName",
        name: "firstName",
        toggle_class: "sameDrh-false",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Fonction",
        id: "function",
        name: "function",
        toggle_class: "sameDrh-false",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "email"],
        label: "E-mail",
        id: "email",
        name: "email",
        toggle_class: "sameDrh-false",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "phone_number"],
        label: "Téléphone",
        id: "phone",
        name: "phone",
        toggle_class: "sameDrh-false",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
  ],
};
