import { i18n } from "@/i18n";
import LayoutView from "@/views/Training/LayoutView.vue";
import TrainingListView from "@/views/Training/TrainingListView.vue";
import TrainingDashboardView from "@/views/Training/TrainingDashboardView.vue";
import PreInscriptionSheetView from "@/views/Inscription/PreInscriptionSheetView.vue";
import ContractSheetView from "@/views/Inscription/ContractSheetView.vue";

export default {
  path: "/training",
  name: "Training",
  component: LayoutView,
  redirect: { name: "TrainingList" },
  meta: {
    breadcrumb: true,
    authRequired: true,
    title: i18n.global.t("page.training_list"),
  },
  children: [
    {
      path: "list",
      name: "TrainingList",
      component: TrainingListView,
      meta: {
        title: i18n.global.t("page.training_list"),
        breadcrumb: false,
      },
    },
    {
      path: ":id",
      name: "TrainingDashboard",
      component: TrainingDashboardView,
      meta: {
        title: i18n.global.t("page.training_dashboard"),
        breadcrumb: true,
      },
    },
    {
      path: "pre-inscription/:step",
      name: "TrainingPreInscription",
      component: PreInscriptionSheetView,
      meta: {
        title: i18n.global.t("page.pre_inscription"),
        breadcrumb: true,
      },
    },
    {
      path: "contract",
      name: "TrainingContract",
      component: ContractSheetView,
      meta: {
        title: i18n.global.t("page.contract"),
        breadcrumb: true,
      },
    },
  ],
};
