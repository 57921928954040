export const contractSecondMap = {
  label: "2ème maître d'apprentissage",
  code: "second_map",
  models: [
    {
      vars: {
        block_prefixes: ["", "switch"],
        label: "Ajouter un deuxième maître d'apprentissage",
        row_attr: {
          class: "col-lg-12",
        },
        toggler_target: "secondMap",
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Genre",
        toggle_class: "secondMap-true",
        id: "gender",
        name: "gender",
        required: true,
        choices: [
          {
            label: "Mme",
            value: "Mme",
            data: "Mme",
          },
          {
            label: "M.",
            value: "M.",
            data: "M.",
          },
        ],
        attr: {
          placeholder: "Sélectionner un genre",
        },
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Nom",
        toggle_class: "secondMap-true",
        id: "lastName",
        name: "lastName",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Prénom",
        toggle_class: "secondMap-true",
        id: "firstName",
        name: "firstName",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Fonction",
        toggle_class: "secondMap-true",
        id: "function",
        name: "function",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "email"],
        label: "E-mail",
        toggle_class: "secondMap-true",
        id: "email",
        name: "email",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "phone_number"],
        label: "Téléphone",
        toggle_class: "secondMap-true",
        id: "phone",
        name: "phone",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "date"],
        label: "Date de naissance",
        toggle_class: "secondMap-true",
        id: "birthDate",
        name: "birthDate",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        toggle_class: "secondMap-true",
        label: "Diplôme ou titre le plus élevé obtenu",
        id: "best_diplome",
        name: "best_diplome",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        toggle_class: "secondMap-true",
        label: "Niveau de diplôme ou titre le plus élevé obtenu",
        id: "best_diplome_level",
        name: "best_diplome_level",
        choices: [
          {
            label: "Licence",
            value: "Licence",
            data: "Licence",
          },
          {
            label: "BTS",
            value: "BTS",
            data: "BTS",
          },
          {
            label: "Master",
            value: "Master",
            data: "Master",
          },
          {
            label: "Doctorat",
            value: "Doctorat",
            data: "Doctorat",
          },
        ],
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "switch"],
        toggle_class: "secondMap-true",
        label: "Cette personne a déjà été maître d'apprentissage",
        row_attr: {
          class: "col-lg-6",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "switch"],
        toggle_class: "secondMap-true",
        label:
          "Cette personne a déjà reçu une formation de maître d'apprentissage",
        row_attr: {
          class: "col-lg-6",
        },
      },
    },
  ],
};
