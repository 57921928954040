export const compensationCompensation = {
  label: "Rémunération",
  code: "compensation",
  models: [
    {
      vars: {
        block_prefixes: ["", "subtitle"],
        label:
          "La rémunération minimale dans le cadre d’un contrat d’apprentissage est définie par le code du travail et/ou la convention collective de l’entreprise selon plusieurs critères :",
      },
    },
    {
      vars: {
        block_prefixes: ["", "title"],
        label: "L’année suivie dans le cycle de formation",
      },
    },
    {
      vars: {
        block_prefixes: ["", "subtitle"],
        label:
          " Le cycle de formation est un <strong>LICENCE PRO</strong> préparé sur <strong>12 mois</strong>. \n L‘Article D6222-32 du décret du 30 mars 2020 prévoit une rémunération minimale de 2ème année pour les Licences Professionnelles.",
      },
    },
    {
      vars: {
        block_prefixes: ["", "title"],
        label: "L'âge de l'apprenti.e",
      },
    },
    {
      vars: {
        block_prefixes: ["", "subtitle"],
        label:
          "Votre apprenti a <strong>19 ans</strong> le 1er jour du contrat.",
      },
    },
    {
      vars: {
        block_prefixes: ["", "title"],
        label:
          "L'année du cycle de formation faisant l’objet de l’apprentissage",
      },
    },
    {
      vars: {
        block_prefixes: ["", "subtitle"],
        label:
          " Si l’apprenti.e a suivi sa dernière année en apprentissage, l’année de rémunération ne peut être inférieure à celle dont il bénéficiait à la fin de son contrat précédent.",
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Année antérieure de rémunération :",
        id: "anterior_compensation_year",
        name: "anterior_compensation_year",
        required: true,
        choices: [
          {
            label: "1ère année",
            data: "1ère année",
            value: "1ère année",
          },
          {
            label: "2ème année",
            data: "2ème année",
            value: "2ème année",
          },
        ],
        attr: {
          placeholder: "Sélectionner une année",
        },
        row_attr: {
          class: "col-lg-6",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "title"],
        label: "La convention collective de l’entreprise",
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label:
          " La convention collective de l’entreprise prévoit-elle un salaire minimal conventionnel (SMC) ? ",
        id: "anterior_compensation_year",
        name: "anterior_compensation_year",
        required: true,
        choices: [
          {
            label: "Oui",
            data: "Oui",
            value: "Oui",
          },
          {
            label: "Non",
            data: "Non",
            value: "Non",
          },
        ],
        attr: {
          placeholder: "Sélectionner une valeur",
        },
        row_attr: {
          class: "col-lg-6",
        },
      },
    },
  ],
};
