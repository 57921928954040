<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-7 col-xl-9">
        <div class="widget">
          <div class="widget-heading">
            <h2 class="textStyle">Formation {{ training.name }}</h2>
          </div>
          <div class="widget-content position-relative">
            <div class="panel-body simple-pills tabs">
              <ul id="trainingTab" class="nav nav-pills" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    id="tab-info-tab"
                    ref="ref-info"
                    aria-controls="tab-info"
                    :aria-selected="
                      trainingTabCurrentCode &&
                      trainingTabCurrentCode === 'info'
                    "
                    :class="
                      'nav-link mb-2 text-center bgStyle h-100' +
                      (trainingTabCurrentCode &&
                      trainingTabCurrentCode === 'info'
                        ? ' active'
                        : '')
                    "
                    href="#tab-info"
                    data-bs-toggle="pill"
                    role="tab"
                    @click="toggleTab('info')"
                  >
                    Informations générales
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    id="tab-rncp-tab"
                    ref="ref-rncp"
                    aria-controls="tab-rncp"
                    :aria-selected="
                      trainingTabCurrentCode &&
                      trainingTabCurrentCode === 'rncp'
                    "
                    :class="
                      'nav-link mb-2 text-center bgStyle h-100' +
                      (trainingTabCurrentCode &&
                      trainingTabCurrentCode === 'rncp'
                        ? ' active'
                        : '')
                    "
                    href="#tab-rncp"
                    data-bs-toggle="pill"
                    role="tab"
                    @click="toggleTab('rncp')"
                  >
                    Fiche RNCP
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    id="tab-settings-tab"
                    ref="ref-settings"
                    aria-controls="tab-settings"
                    :aria-selected="
                      trainingTabCurrentCode &&
                      trainingTabCurrentCode === 'settings'
                    "
                    :class="
                      'nav-link mb-2 text-center bgStyle h-100' +
                      (trainingTabCurrentCode &&
                      trainingTabCurrentCode === 'settings'
                        ? ' active'
                        : '')
                    "
                    href="#tab-settings"
                    data-bs-toggle="pill"
                    role="tab"
                    @click="toggleTab('settings')"
                  >
                    Paramétrage Inscription
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    id="tab-npec-tab"
                    ref="ref-npec"
                    aria-controls="tab-npec"
                    :aria-selected="
                      trainingTabCurrentCode &&
                      trainingTabCurrentCode === 'npec'
                    "
                    :class="
                      'nav-link mb-2 text-center bgStyle h-100' +
                      (currentTabCode && currentTabCode === 'npec'
                        ? ' active'
                        : '')
                    "
                    href="#tab-npec"
                    data-bs-toggle="pill"
                    role="tab"
                    @click="toggleTab('npec')"
                  >
                    NPEC
                  </a>
                </li>
              </ul>
              <div id="trainingTabContent" class="tab-content">
                <div
                  id="tab-info"
                  aria-labelledby="tab-info-tab"
                  :class="
                    'tab-pane fade' +
                    (trainingTabCurrentCode && trainingTabCurrentCode === 'info'
                      ? ' show active'
                      : '')
                  "
                  role="tabpanel"
                >
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="tabs__row">
                        <div class="field">Nom de l'établissement</div>
                        <div class="text">
                          {{
                            training.institution
                              ? training.institution.name
                              : ""
                          }}
                        </div>
                      </div>
                      <div class="tabs__row">
                        <div class="field">Référence</div>
                        <div class="text">{{ training.reference }}</div>
                      </div>
                      <div class="tabs__row">
                        <div class="field">Numéro RNCP</div>
                        <div class="text">
                          {{
                            training.titleRncp ? training.titleRncp.name : ""
                          }}
                        </div>
                      </div>
                      <div class="tabs__row">
                        <div class="field">Nom officiel</div>
                        <div class="text">
                          {{ training.name }}
                        </div>
                      </div>
                      <div class="tabs__row">
                        <div class="field">Type de diplôme</div>
                        <div class="text">
                          {{ training.degree ? training.degree.shortName : "" }}
                        </div>
                      </div>
                      <div class="tabs__row">
                        <div class="field">Nom/mention</div>
                        <div class="text">
                          {{ training.name }}
                        </div>
                      </div>
                      <div class="tabs__row">
                        <div class="field">Parcours</div>
                        <div class="text"></div>
                      </div>
                      <div class="tabs__row">
                        <div class="field">Code diplôme</div>
                        <div class="text"></div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="tabs__row">
                        <div class="field">Statut</div>
                        <div class="text">
                          <span
                            class="badge"
                            :class="{
                              'bg-success': !training.archive,
                              'bg-danger': training.archive,
                            }"
                            >{{ training.archive ? "Archivé" : "Active" }}</span
                          >
                        </div>
                      </div>
                      <div class="tabs__row">
                        <div class="field">Logo</div>
                        <div class="text">
                          <img :src="logo" v-if="logo" :alt="training.name" />
                        </div>
                      </div>
                      <div class="tabs__row">
                        <div class="field">URL du site partenaire</div>
                        <div class="text">
                          <a target="_blank" href="#">Lien</a>
                        </div>
                      </div>
                      <div class="tabs__row">
                        <div class="field">Durée en alternance</div>
                        <div class="text">{{ training.duration }} mois</div>
                      </div>
                      <div class="tabs__row">
                        <div class="field">Durée total du cycle</div>
                        <div class="text">{{ training.duration }} mois</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="tab-rncp"
                  aria-labelledby="tab-rncp-tab"
                  :class="
                    'tab-pane fade' +
                    (trainingTabCurrentCode && trainingTabCurrentCode === 'rncp'
                      ? ' show active'
                      : '')
                  "
                  role="tabpanel"
                ></div>

                <div
                  id="tab-settings"
                  aria-labelledby="tab-settings-tab"
                  :class="
                    'tab-pane fade' +
                    (trainingTabCurrentCode &&
                    trainingTabCurrentCode === 'settings'
                      ? ' show active'
                      : '')
                  "
                  role="tabpanel"
                ></div>

                <div
                  id="tab-npec"
                  aria-labelledby="tab-npec-tab"
                  :class="
                    'tab-pane fade' +
                    (trainingTabCurrentCode && trainingTabCurrentCode === 'npec'
                      ? ' show active'
                      : '')
                  "
                  role="tabpanel"
                ></div>
              </div>
            </div>
            <BlockLoader :loading="loading" />
          </div>
        </div>
        <div class="widget faq">
          <div class="widget-heading">
            <h2 class="textStyle">Promotions</h2>
          </div>
          <div class="widget-content faq-layouting position-relative">
            <div class="fq-tab-section">
              <div id="trainingCourseList" class="accordion">
                <div
                  class="card"
                  v-for="(
                    trainingCourse, index
                  ) in training.activeTrainingCourses"
                  :key="trainingCourse.id"
                >
                  <div class="card-header" role="tab">
                    <div
                      :aria-controls="'collapse-' + trainingCourse.id"
                      :aria-expanded="!index"
                      :data-bs-target="'#collapse-' + trainingCourse.id"
                      class="mb-0"
                      data-bs-toggle="collapse"
                      role="menu"
                    >
                      <span class="faq-q-title textStyle">
                        <span :class="{ green: true, red: false }"
                          ><FontAwesomeIcon icon="fa-light fa-check"
                        /></span>
                        {{ trainingCourse.displayName }}
                      </span>
                      <div class="like-faq d-flex align-items-center">
                        <router-link
                          :to="{
                            name: 'studeaManagerTriadList',
                            params: { trainingCourseId: trainingCourse.id },
                          }"
                          class="settings"
                          v-tooltip="{
                            title: $t('dashboard.training.triad_list'),
                          }"
                        >
                          <FontAwesomeIcon
                            class="icon"
                            icon="fa-light fa-users"
                          />
                        </router-link>

                        <router-link
                          :to="{
                            name: 'studeaManagerHome',
                            params: { trainingCourseId: trainingCourse.id },
                          }"
                          class="settings"
                          v-tooltip="{
                            title:
                              $tc('studea_manager.home.title', 1) +
                              $tc(
                                'studea_manager.trainingCourse.title',
                                1,
                              ).toLowerCase(),
                          }"
                        >
                          <FontAwesomeIcon
                            class="icon"
                            icon="fa-light fa-gear"
                          />
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div
                    :id="'collapse-' + trainingCourse.id"
                    :aria-labelledby="'collapse-' + trainingCourse.id"
                    class="collapse"
                    :class="{ show: !index }"
                    data-bs-parent="#trainingCourseList"
                  >
                    <div class="collapse-content">
                      <div class="row">
                        <div
                          :class="
                            'col-' +
                            (Object.keys(trainingCourse.crews).length > 2
                              ? '4'
                              : Object.keys(trainingCourse.crews).length > 1
                                ? '6'
                                : '12')
                          "
                          v-for="crew in trainingCourse.crews"
                          :key="crew.id"
                        >
                          <div
                            :class="{
                              bordered:
                                Object.keys(trainingCourse.crews).length > 1,
                            }"
                          >
                            <CrewComponent
                              :crew="crew"
                              :trainingCourse="trainingCourse"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <BlockLoader
              :loading="
                !training.activeTrainingCourses ||
                !Object.keys(training.activeTrainingCourses).length
              "
            />
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-xl-3">
        <ActionsBlock :actions="actions" />

        <div class="widget">
          <div class="widget-heading align-items-center">
            <h2>Contacts</h2>
            <button class="btn btn-success btnStyle">
              <FontAwesomeIcon icon="fa-light fa-plus" />
            </button>
          </div>
          <div class="widget-content position-relative">
            <FormComponent v-if="models" :models="models" :hideSubmit="true" />
            <SubmitTypeComponent :model="submitModel" @submitForm="submit" />
            <BlockLoader :loading="loading" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import { mapActions } from "pinia";
import { useMainStore } from "@/store/main/main";
import crews from "@/model/training/crews";
import { trainingManager } from "@/services/training/training-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import CrewComponent from "@/components/triad/CrewComponent.vue";
import { pictureManager } from "@/services/utilities/picture-manager";
import FormComponent from "@/components/FormComponent.vue";
import SubmitTypeComponent from "@/components/form/SubmitTypeComponent.vue";
import { formManager } from "@/services/form/form-manager";

export default {
  name: "TrainingDashboardView",
  components: {
    SubmitTypeComponent,
    FormComponent,
    CrewComponent,
    FontAwesomeIcon,
    BlockLoader,
    ActionsBlock,
  },
  data() {
    return {
      trainingTabCurrentCode: "info",
      trainingCourseTabCurrentCode: "2025-2027",
      crews: crews,
      training: {},
      filteredTriadsCrews: [],
      loading: false,
      logo: null,
      models: null,
      submitModel: {
        vars: {
          hideCancel: true,
        },
      },
      actions: [
        {
          actionType: "button",
          icon: "pen",
          type: "primary",
          action: "test",
          translation: "Modifier la formation",
          cfaStyle: true,
        },
        {
          actionType: "button",
          icon: "download",
          type: "primary",
          action: "test",
          translation: "Exporter",
          cfaStyle: true,
        },
        {
          actionType: "button",
          icon: "download",
          type: "primary",
          action: "test",
          translation: "Export des inscriptions",
          cfaStyle: true,
        },
        {
          actionType: "router",
          icon: "eye",
          type: "primary",
          action: "TrainingPreInscription",
          translation: "Voir la fiche de pré-inscription",
          cfaStyle: true,
          params: { step: 1 },
        },
        {
          actionType: "router",
          icon: "eye",
          type: "primary",
          action: "TrainingContract",
          translation: "Voir la fiche contrat",
          cfaStyle: true,
        },
      ],
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.init();
    this.setAppLoading(false);
  },
  methods: {
    init() {
      this.loading = true;
      this.getTraining();
      this.getContactForm();
    },

    getTraining() {
      trainingManager.get(this.$route.params.id).then((res) => {
        this.training = res;
        this.setLogoSrc();
        this.loading = false;
      });
    },

    submit() {
      const result = formManager.processForm(this.models);
      console.log(result);
    },

    getContactForm() {
      trainingManager.form("contact", this.$route.params.id).then((res) => {
        this.models = res;
      });
    },

    toggleTab(code) {
      this.setCurrentTabCode(code);
    },

    setCurrentTabCode(code) {
      this.currentTabCode = code;
      window.location.hash = this.currentTabCode;
    },

    setLogoSrc() {
      this.logo = pictureManager.setPicture(this.training.logoTraining);
    },

    ...mapActions(useMainStore, ["setAppLoading"]),
  },
};
</script>

<style lang="scss" scoped>
.tab-content {
  .tabs {
    &__row {
      padding: 8px 0;
      display: flex;
      font-size: 14px;
      align-items: center;
      min-height: 40px;

      .field {
        width: 35%;
        font-weight: 600;
      }

      .text {
        width: 65%;
      }

      img {
        width: 100%;
        max-width: 100%;
      }
    }

    &__triads {
      margin-top: 1rem;
      border: 1px dashed $m-color_6;
      border-radius: $radius;
      padding: 1rem;
      max-height: 400px;
      overflow: auto;
      height: 100%;

      .triad {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
      }

      .triad + .triad {
        border-top: 1px dashed $m-color_6;
      }
    }
  }

  .tabs__row + .tabs__row {
    border-top: 1px dashed $m-color_6;
  }
}

.settings {
  width: 35px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:hover {
    font-size: 20px;
    color: $green;
  }
}

.settings + .settings {
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 12px;
    width: 1px;
    background: var(--primary-color);

    @include up-to-md {
      height: 0;
      width: 0;
    }
  }
}

.collapse-content {
  padding: 20px;
}
</style>
