export const apprenticePersonnalInformations = {
  label: "Informations personnelles",
  code: "personnal_infos",
  models: [
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Genre",
        id: "gender",
        name: "gender",
        required: true,
        choices: [
          {
            label: "Mme",
            value: "Mme",
            data: "Mme",
          },
          {
            label: "M.",
            value: "M.",
            data: "M.",
          },
        ],
        attr: {
          placeholder: "Sélectionner un genre",
        },
        row_attr: {
          class: "col-lg-3",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Nom de naissance",
        id: "birth_lastName",
        name: "birth_lastName",
        required: true,
        row_attr: {
          class: "col-lg-3",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Nom d'usage",
        id: "use_lastName",
        name: "use_lastName",
        required: true,
        row_attr: {
          class: "col-lg-3",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Premier prénom",
        id: "firstName",
        help: "Premier prénom de l’apprenti selon l’état civil",
        name: "firstName",
        required: true,
        row_attr: {
          class: "col-lg-3",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "date"],
        label: "Date de naissance",
        id: "birthDate",
        name: "birthDate",
        required: true,
        row_attr: {
          class: "col-lg-3",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Pays de naissance",
        id: "birth_country",
        name: "birth_country",
        required: true,
        choices: [
          {
            label: "France",
            value: "France",
            data: "France",
          },
          {
            label: "Espagne",
            value: "Espagne",
            data: "Espagne",
          },
          {
            label: "Colombie",
            value: "Colombie",
            data: "Colombie",
          },
        ],
        attr: {
          placeholder: "Sélectionner un pays",
        },
        row_attr: {
          class: "col-lg-3",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Nationalité",
        id: "nationality",
        required: true,
        name: "nationality",
        choices: [
          {
            label: "Français(e)",
            value: "Français(e)",
            data: "Français(e)",
          },
          {
            label: "Espagnol(e)",
            value: "Espagnol(e)",
            data: "Espagnol(e)",
          },
          {
            label: "Colombien(ne)",
            value: "Colombien(ne)",
            data: "Colombien(ne)",
          },
        ],
        attr: {
          placeholder: "Sélectionner une nationalité",
        },
        row_attr: {
          class: "col-lg-3",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Reconnaissance Travailleur Handicapé",
        line_break: true,
        help: "Pas de limite d'âge si reconnaissance qualité travailleur handicapé. Il conviendra de joindre le justificatif de la MDPH.",
        id: "rqth",
        required: true,
        name: "rqth",
        choices: [
          {
            label: "Oui",
            value: "Oui",
            data: "Oui",
          },
          {
            label: "Non",
            value: "Non",
            data: "Non",
          },
          {
            label: "En cours",
            value: "En cours",
            data: "En cours",
          },
        ],
        attr: {
          placeholder: "Sélectionner une nationalité",
        },
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Numéro de sécurité sociale",
        id: "secu",
        name: "secu",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Sportif, entraineur, arbitre et/ou juge sportif...",
        id: "type",
        required: true,
        name: "type",
        choices: [
          {
            label: "Oui",
            value: "Oui",
            data: "Oui",
          },
          {
            label: "Non",
            value: "Non",
            data: "Non",
          },
        ],
        attr: {
          placeholder: "Sélectionner une nationalité",
        },
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Permis B",
        id: "permis_b",
        required: true,
        name: "permis_b",
        line_break: true,
        choices: [
          {
            label: "Oui",
            value: "Oui",
            data: "Oui",
          },
          {
            label: "Non",
            value: "Non",
            data: "Non",
          },
        ],
        attr: {
          placeholder: "Sélectionner une valeur",
        },
        row_attr: {
          class: "col-lg-6",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Projet de création ou reprise d'entreprise",
        id: "company_creation",
        required: true,
        name: "company_creation",
        choices: [
          {
            label: "Oui",
            value: "Oui",
            data: "Oui",
          },
          {
            label: "Non",
            value: "Non",
            data: "Non",
          },
        ],
        attr: {
          placeholder: "Sélectionner une valeur",
        },
        row_attr: {
          class: "col-lg-6",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "title"],
        label: "Coordonnées",
        separator: true,
      },
    },
    {
      vars: {
        block_prefixes: ["", "phone_number"],
        line_break: true,
        label: "Téléphone",
        id: "phone",
        name: "phone",
        required: true,
        row_attr: {
          class: "col-lg-6",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "phone_number"],
        label: "2ème Téléphone",
        id: "second_phone",
        name: "second_phone",
        row_attr: {
          class: "col-lg-6",
        },
        attr: {
          placeholder: "Optionnel",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "email"],
        label: "E-mail",
        id: "email",
        name: "email",
        required: true,
        row_attr: {
          class: "col-lg-6",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "email"],
        label: "2ème e-mail",
        id: "second_email",
        name: "second_email",
        row_attr: {
          class: "col-lg-6",
        },
        attr: {
          placeholder: "Optionnel",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "title"],
        label: "Adresse",
        separator: true,
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "N°",
        id: "address_number",
        name: "address_number",
        row_attr: {
          class: "col-lg-2",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Adresse",
        id: "address_address",
        name: "address_address",
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Complément",
        id: "address_complement",
        name: "address_complement",
        row_attr: {
          class: "col-lg-3",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Commune",
        id: "address_city",
        name: "address_city",
        row_attr: {
          class: "col-lg-3",
        },
      },
    },
  ],
};
