export const contractMap = {
  label: "Maître d'apprentissage",
  code: "map",
  models: [
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Genre",
        id: "gender",
        name: "gender",
        required: true,
        choices: [
          {
            label: "Mme",
            value: "Mme",
            data: "Mme",
          },
          {
            label: "M.",
            value: "M.",
            data: "M.",
          },
        ],
        attr: {
          placeholder: "Sélectionner un genre",
        },
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Nom",
        id: "lastName",
        name: "lastName",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Prénom",
        id: "firstName",
        name: "firstName",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Fonction",
        id: "function",
        name: "function",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "email"],
        label: "E-mail",
        id: "email",
        name: "email",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "phone_number"],
        label: "Téléphone",
        id: "phone",
        name: "phone",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "date"],
        label: "Date de naissance",
        id: "birthDate",
        name: "birthDate",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Diplôme ou titre le plus élevé obtenu",
        id: "best_diplome",
        name: "best_diplome",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Niveau de diplôme ou titre le plus élevé obtenu",
        id: "best_diplome_level",
        name: "best_diplome_level",
        choices: [
          {
            label: "Licence",
            value: "Licence",
            data: "Licence",
          },
          {
            label: "BTS",
            value: "BTS",
            data: "BTS",
          },
          {
            label: "Master",
            value: "Master",
            data: "Master",
          },
          {
            label: "Doctorat",
            value: "Doctorat",
            data: "Doctorat",
          },
        ],
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "switch"],
        label: "Cette personne a déjà été maître d'apprentissage",
        row_attr: {
          class: "col-lg-6",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "switch"],
        label:
          "Cette personne a déjà reçu une formation de maître d'apprentissage",
        row_attr: {
          class: "col-lg-6",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "switch"],
        label:
          "L’employeur atteste sur l’honneur que le maître d’apprentissage répond à l’ensemble des critères d’éligibilité à cette fonction",
        row_attr: {
          class: "col-lg-12",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "switch"],
        label:
          "L’entreprise bénéficiera de la formation maitre d’apprentissage dénomée « TAM digitale », financée par l’OPCO",
        row_attr: {
          class: "col-lg-12",
        },
      },
    },
  ],
};
