export default [
  {
    id: 8,
    title: "Groupe 1",
    triads: [
      {
        activeContract: {
          activeContract: false,
          apprenticeMaster: {
            lastSendReport: false,
            resetNumber: 0,
            welcomeNumber: 0,
            fullName: "Map TEST_501",
            firstName: "map",
            lastName: "Test_501",
            email: "map_Test_501@test-mail.studea.fr",
            roles: [],
            alreadyConnectedOnce: true,
            id: 30,
            cryptedId: "32d35015-7658-11ef-bae3-0242ac190006",
          },
          apprenticeMaster2: null,
          company: {
            address1: "",
            address2: "",
            addressCity: null,
            addressComplement: null,
            city: "",
            companyName: "",
            phoneNumber: null,
            postCode: "",
            siretNumber: "",
            id: 2,
          },
          contractNumber: "",
          contractType: null,
          duration: null,
          endDate: "2025-07-01",
          manager: null,
          ruptureCause: "",
          ruptureDate: null,
          startDate: "2024-10-01",
          id: 2,
          cryptedId: "66ec1b9e9fa97594908545",
        },
        contracts: [
          {
            activeContract: true,
            apprenticeMaster: {
              lastSendReport: false,
              resetNumber: 0,
              welcomeNumber: 0,
              fullName: "Map TEST_501",
              firstName: "map",
              lastName: "Test_501",
              email: "map_Test_501@test-mail.studea.fr",
              roles: [],
              alreadyConnectedOnce: true,
              id: 30,
              cryptedId: "32d35015-7658-11ef-bae3-0242ac190006",
            },
            apprenticeMaster2: null,
            company: {
              address1: "",
              address2: "",
              addressCity: null,
              addressComplement: null,
              city: "",
              companyName: "",
              phoneNumber: null,
              postCode: "",
              siretNumber: "",
              id: 2,
            },
            contractNumber: "",
            contractType: null,
            duration: null,
            endDate: "2025-07-01",
            manager: null,
            ruptureCause: "",
            ruptureDate: null,
            startDate: "2024-10-01",
            id: 2,
            cryptedId: "66ec1b9e9fa97594908545",
          },
          {
            activeContract: false,
            apprenticeMaster: null,
            apprenticeMaster2: null,
            company: {
              address1: "",
              address2: "",
              addressCity: null,
              addressComplement: null,
              city: "",
              companyName: "",
              phoneNumber: null,
              postCode: "",
              siretNumber: "",
              id: 1,
            },
            contractNumber: "",
            contractType: null,
            duration: null,
            endDate: "2024-09-30",
            manager: null,
            ruptureCause: "",
            ruptureDate: "2024-09-19",
            startDate: "2024-09-01",
            id: 1,
            cryptedId: "66ec039a7cd73673641603",
          },
        ],
        isAlreadyQuestionnaireSigned: true,
        contractRuptureDate: null,
        active: true,
        apprentice: {
          lastSendReport: false,
          resetNumber: 0,
          welcomeNumber: 0,
          fullName: "App TEST_501",
          firstName: "app",
          lastName: "Test_501",
          email: "app_Test_501@test-mail.studea.fr",
          roles: [],
          alreadyConnectedOnce: true,
          id: 28,
          cryptedId: "32d34ec6-7658-11ef-bae3-0242ac190006",
        },
        apprenticeMaster: {
          lastSendReport: false,
          resetNumber: 0,
          welcomeNumber: 0,
          fullName: "Map TEST_501",
          firstName: "map",
          lastName: "Test_501",
          email: "map_Test_501@test-mail.studea.fr",
          roles: [],
          alreadyConnectedOnce: true,
          id: 30,
          cryptedId: "32d35015-7658-11ef-bae3-0242ac190006",
        },
        triadUsers: [],
        tutor: {
          lastSendReport: false,
          resetNumber: 0,
          welcomeNumber: 0,
          fullName: "Tut TEST_501",
          firstName: "tut",
          lastName: "Test_501",
          email: "tut_Test_501@test-mail.studea.fr",
          roles: [],
          alreadyConnectedOnce: true,
          id: 29,
          cryptedId: "32d34eff-7658-11ef-bae3-0242ac190006",
        },
        id: 9,
        cryptedId: "32ea9c2b-7658-11ef-bae3-0242ac190006",
      },
      {
        activeContract: null,
        contracts: [],
        isAlreadyQuestionnaireSigned: false,
        contractRuptureDate: null,
        active: true,
        apprentice: {
          lastSendReport: false,
          resetNumber: 0,
          welcomeNumber: 0,
          fullName: "App TEST9_957",
          firstName: "app",
          lastName: "Test9_957",
          email: "app_Test9_957@test-mail.studea.fr",
          roles: [],
          alreadyConnectedOnce: false,
          id: 65,
          cryptedId: "32d42fd8-7658-11ef-bae3-0242ac190006",
        },
        apprenticeMaster: {
          lastSendReport: false,
          resetNumber: 0,
          welcomeNumber: 0,
          fullName: "Map TEST9_957",
          firstName: "map",
          lastName: "Test9_957",
          email: "map_Test9_957@test-mail.studea.fr",
          roles: [],
          alreadyConnectedOnce: true,
          id: 67,
          cryptedId: "32d43206-7658-11ef-bae3-0242ac190006",
        },
        triadUsers: [],
        tutor: {
          lastSendReport: false,
          resetNumber: 0,
          welcomeNumber: 0,
          fullName: "Tut TEST9_957",
          firstName: "tut",
          lastName: "Test9_957",
          email: "tut_Test9_957@test-mail.studea.fr",
          roles: [],
          alreadyConnectedOnce: false,
          id: 66,
          cryptedId: "32d430f1-7658-11ef-bae3-0242ac190006",
        },
        id: 21,
        cryptedId: "32ea9e04-7658-11ef-bae3-0242ac190006",
      },
      {
        activeContract: null,
        contracts: [],
        isAlreadyQuestionnaireSigned: false,
        contractRuptureDate: null,
        active: true,
        apprentice: {
          lastSendReport: false,
          resetNumber: 0,
          welcomeNumber: 0,
          fullName: "App TEST9_663",
          firstName: "app",
          lastName: "Test9_663",
          email: "app_Test9_663@test-mail.studea.fr",
          roles: [],
          alreadyConnectedOnce: true,
          id: 68,
          cryptedId: "32d4331c-7658-11ef-bae3-0242ac190006",
        },
        apprenticeMaster: {
          lastSendReport: false,
          resetNumber: 0,
          welcomeNumber: 0,
          fullName: "Map TEST9_663",
          firstName: "map",
          lastName: "Test9_663",
          email: "map_Test9_663@test-mail.studea.fr",
          roles: [],
          alreadyConnectedOnce: false,
          id: 70,
          cryptedId: "32d452c2-7658-11ef-bae3-0242ac190006",
        },
        triadUsers: [],
        tutor: null,
        id: 22,
        cryptedId: "32ea9e2c-7658-11ef-bae3-0242ac190006",
      },
      {
        activeContract: null,
        contracts: [],
        isAlreadyQuestionnaireSigned: false,
        contractRuptureDate: null,
        active: true,
        apprentice: {
          lastSendReport: false,
          resetNumber: 0,
          welcomeNumber: 0,
          fullName: "App TEST9_726",
          firstName: "app",
          lastName: "Test9_726",
          email: "app_Test9_726@test-mail.studea.fr",
          roles: [],
          alreadyConnectedOnce: false,
          id: 71,
          cryptedId: "32d452fe-7658-11ef-bae3-0242ac190006",
        },
        apprenticeMaster: {
          lastSendReport: false,
          resetNumber: 0,
          welcomeNumber: 0,
          fullName: "Map TEST9_726",
          firstName: "map",
          lastName: "Test9_726",
          email: "map_Test9_726@test-mail.studea.fr",
          roles: [],
          alreadyConnectedOnce: false,
          id: 73,
          cryptedId: "32d45369-7658-11ef-bae3-0242ac190006",
        },
        triadUsers: [],
        tutor: {
          lastSendReport: false,
          resetNumber: 0,
          welcomeNumber: 0,
          fullName: "Tut TEST9_726",
          firstName: "tut",
          lastName: "Test9_726",
          email: "tut_Test9_726@test-mail.studea.fr",
          roles: [],
          alreadyConnectedOnce: false,
          id: 72,
          cryptedId: "32d45334-7658-11ef-bae3-0242ac190006",
        },
        id: 23,
        cryptedId: "32ea9e52-7658-11ef-bae3-0242ac190006",
      },
    ],
  },
  {
    id: 19,
    title: "Groupe 2",
    triads: [
      {
        activeContract: null,
        contracts: [],
        isAlreadyQuestionnaireSigned: false,
        contractRuptureDate: null,
        active: true,
        apprentice: {
          lastSendReport: false,
          resetNumber: 0,
          welcomeNumber: 0,
          fullName: "App TEST9_613",
          firstName: "app",
          lastName: "Test9_613",
          email: "app_Test9_613@test-mail.studea.fr",
          roles: [],
          alreadyConnectedOnce: false,
          id: 61,
          cryptedId: "32d42b6f-7658-11ef-bae3-0242ac190006",
        },
        apprenticeMaster: {
          lastSendReport: false,
          resetNumber: 0,
          welcomeNumber: 0,
          fullName: "Map TEST9_613",
          firstName: "map",
          lastName: "Test9_613",
          email: "map_Test9_613@test-mail.studea.fr",
          roles: [],
          alreadyConnectedOnce: false,
          id: 63,
          cryptedId: "32d42d98-7658-11ef-bae3-0242ac190006",
        },
        triadUsers: [],
        tutor: {
          lastSendReport: false,
          resetNumber: 0,
          welcomeNumber: 0,
          fullName: "Tut TEST9_613",
          firstName: "tut",
          lastName: "Test9_613",
          email: "tut_Test9_613@test-mail.studea.fr",
          roles: [],
          alreadyConnectedOnce: false,
          id: 62,
          cryptedId: "32d42d3b-7658-11ef-bae3-0242ac190006",
        },
        id: 20,
        cryptedId: "32ea9dd8-7658-11ef-bae3-0242ac190006",
      },
    ],
  },
];
