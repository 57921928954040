<template>
  <div class="crew">
    <div class="crew__heading">
      <div class="d-flex align-items-center">
        <h6 class="m-0">
          <span class="fw-bold">{{ crew.title }}</span>
          -
          <span class="fst-italic"
            >{{ Object.keys(crew.triads).length }} livrets</span
          >
        </h6>
        <span class="crew__status">
          <FontAwesomeIcon icon="fa-light fa-circle-check" />
        </span>
      </div>
      <div>
        <button
          class="btn-icon"
          v-tooltip="{
            title: $tc('global.edit.title', 1, {
              name: this.$tc('studea_manager.crew.title', 1).toLowerCase(),
            }),
          }"
        >
          <FontAwesomeIcon icon="fa-light fa-pen" />
        </button>
      </div>
    </div>
    <div class="crew__content">
      <InputTypeComponent :model="searchModel" @inputModel="filter" />
      <div class="row mt-2">
        <div
          v-for="triad in filteredTriads"
          :key="triad.id"
          :class="
            'col-' +
            (Object.keys(trainingCourse.crews).length > 1
              ? Object.keys(trainingCourse.crews).length > 2
                ? '12'
                : '6'
              : '3')
          "
        >
          <TriadCardComponent :trainingCourse="trainingCourse" :triad="triad" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import TriadCardComponent from "@/components/triad/TriadCardComponent.vue";
import InputTypeComponent from "@/components/form/InputTypeComponent.vue";

export default {
  name: "CrewComponent",
  components: {
    InputTypeComponent,
    TriadCardComponent,
    FontAwesomeIcon,
  },
  props: {
    crew: {
      type: Object,
      required: true,
    },
    trainingCourse: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchModel: {
        vars: {
          name: "searchTriad-" + this.crew.id,
          id: "searchTriad-" + this.crew.id,
          block_prefixes: ["", "text"],
          value: "",
          attr: {
            placeholder: "Filtrer",
          },
        },
      },
      filteredTriads: [],
    };
  },
  computed: {},
  mounted() {
    this.filteredTriads = this.crew.triads;
  },
  methods: {
    filter(model) {
      const keyword = model.vars.value ? model.vars.value.toLowerCase() : "";
      this.filteredTriads = Object.values(this.crew.triads).filter((triad) => {
        return (
          triad.apprentice.firstName.toLowerCase().indexOf(keyword) !== -1 ||
          triad.apprentice.lastName.toLowerCase().indexOf(keyword) !== -1
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.crew {
  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dashed $m-color_6;
    margin-bottom: 12px;
    padding-bottom: 5px;
  }

  &__status {
    color: $green;
    font-size: 17px;
    margin-left: 10px;
  }
}
</style>
