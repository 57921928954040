export const apprenticePreviousSituation = {
  label: "Situation antérieur",
  code: "situation_anterieur",
  help:
    "<p>ATTENTION :  Si vous avez rompu votre précédent contrat d'apprentissage et que votre nouveau contrat débute au lendemain de la date de rupture, merci d'indiquer <span class='text-decoration-underline'>4- Contrat d'apprentissage</span> et de préciser la situation antérieure.</p>" +
    "<p>Si après la rupture de votre précédent contrat d'apprentissage, vous étiez en recherche d'entreprise avant ce nouveau contrat, merci d'indiquer <span class='text-decoration-underline'>8- En formation au CFA, sans contrat suite à rupture.</span></p>" +
    "<p>Si vous avez commencé au 1er jour de votre formation en apprentissage sans employeur, dans la limite d'une durée de 3 mois, merci d'indiquer <span class='text-decoration-underline'>7- En formation au CFA avant signature d'un contrat d'apprentissage.</span></p>" +
    "<p><span class='text-info'>(Si vous avez effectué votre dernier contrat d'apprentissage avec le CFA SUP 2000 ou  Supalia, sélectionnez \"Oui\" à la question : Avez-vous effectué votre dernier contrat d'apprentissage avec le CFA  ?)</span></p>",
  models: [
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Code INE",
        id: "code_ine",
        name: "code_ine",
        row_attr: {
          class: "col-lg-12",
        },
        attr: {
          placeholder: "Optionnel",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Avez-vous déjà été apprenti(e) ?",
        id: "has_been_apprentice",
        required: true,
        name: "has_been_apprentice",
        choices: [
          {
            label: "Oui",
            value: "Oui",
            data: "Oui",
          },
          {
            label: "Non",
            value: "Non",
            data: "Non",
          },
        ],
        attr: {
          placeholder: "Sélectionner une valeur",
        },
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Situation avant le contrat",
        help: "Indiquez ici ce que vous faisiez avant cette inscription ?",
        id: "situation",
        required: true,
        name: "situation",
        choices: [
          {
            label: "1. Scolaire",
            value: "1. Scolaire",
            data: "1. Scolaire",
          },
          {
            label: "3. Étudiant",
            value: "3. Étudiant",
            data: "3. Étudiant",
          },
          {
            label: "5. Contrat de professionnalisation",
            value: "5. Contrat de professionnalisation",
            data: "5. Contrat de professionnalisation",
          },
          {
            label: "6. Contrat aidé",
            value: "6. Contrat aidé",
            data: "6. Contrat aidé",
          },
          {
            label:
              "7. En formation au CFA avant signature d'un contrat d'apprentissage",
            value:
              "7. En formation au CFA avant signature d'un contrat d'apprentissage",
            data: "7. En formation au CFA avant signature d'un contrat d'apprentissage",
          },
          {
            label: "10. Salarié",
            value: "10. Salarié",
            data: "10. Salarié",
          },
          {
            label: "11. Personne à la recherche d'un emploi",
            value: "11. Personne à la recherche d'un emploi",
            data: "11. Personne à la recherche d'un emploi",
          },
          {
            label: "12. Inactif",
            value: "12. Inactif",
            data: "12. Inactif",
          },
        ],
        attr: {
          placeholder: "Sélectionner une valeur",
        },
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "title"],
        label: "Dernier diplôme préparé (ou en cours d'obtention)",
        bordered: true,
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Pays",
        id: "country",
        name: "country",
        required: true,
        choices: [
          {
            label: "France",
            value: "France",
            data: "France",
          },
          {
            label: "Espagne",
            value: "Espagne",
            data: "Espagne",
          },
          {
            label: "Colombie",
            value: "Colombie",
            data: "Colombie",
          },
        ],
        attr: {
          placeholder: "Sélectionner un pays",
        },
        row_attr: {
          class: "col-lg-6",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Établissement",
        id: "institution",
        name: "institution",
        row_attr: {
          class: "col-lg-6",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Type de diplôme",
        id: "type_diplome",
        name: "type_diplome",
        required: true,
        choices: [
          {
            label: "Aucun diplôme ni title professionnel",
            value: "Aucun diplôme ni title professionnel",
            data: "Aucun diplôme ni title professionnel",
          },
          {
            label: "Autre diplôme ou titre de niveau bac",
            value: "Autre diplôme ou titre de niveau bac",
            data: "Autre diplôme ou titre de niveau bac",
          },
          {
            label: "Autre diplôme ou titre de niveau bac +1 ou 2",
            value: "Autre diplôme ou titre de niveau bac +1 ou 2",
            data: "Autre diplôme ou titre de niveau bac +1 ou 2",
          },
          {
            label: "Autre diplôme ou titre de niveau bac +3 ou 4",
            value: "Autre diplôme ou titre de niveau bac +3 ou 4",
            data: "Autre diplôme ou titre de niveau bac +3 ou 4",
          },
          {
            label: "Autre diplôme ou titre de niveau bac +5 ou plus",
            value: "Autre diplôme ou titre de niveau bac +5 ou plus",
            data: "Autre diplôme ou titre de niveau bac +5 ou plus",
          },
        ],
        attr: {
          placeholder: "Sélectionner un type",
        },
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Intitulé diplôme",
        id: "intitule_diplome",
        name: "intitule_diplome",
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Obtention",
        id: "obtention",
        name: "obtention",
        required: true,
        choices: [
          {
            label: "Oui",
            value: "Oui",
            data: "Oui",
          },
          {
            label: "Non",
            value: "Non",
            data: "Non",
          },
        ],
        attr: {
          placeholder: "Sélectionner une valeur",
        },
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Dernière année ou classe suivie par l'apprenti(e)",
        id: "obtention",
        name: "obtention",
        required: true,
        choices: [
          {
            label:
              "L'apprenti.e a suivi la dernière année du cycle de formation et a obtenu le diplôme ou titre",
            value:
              "L'apprenti.e a suivi la dernière année du cycle de formation et a obtenu le diplôme ou titre",
            data: "L'apprenti.e a suivi la dernière année du cycle de formation et a obtenu le diplôme ou titre",
          },
          {
            label:
              "L'apprenti.e a suivi la 1ère année du cycle et l'a validée (examens réussis mais année non diplômante)",
            value:
              "L'apprenti.e a suivi la 1ère année du cycle et l'a validée (examens réussis mais année non diplômante)",
            data: "L'apprenti.e a suivi la 1ère année du cycle et l'a validée (examens réussis mais année non diplômante)",
          },
        ],
        attr: {
          placeholder: "Sélectionner une valeur",
        },
        row_attr: {
          class: "col-lg-12",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "title"],
        label: "Dernier diplôme ou titre le plus élevé obtenu",
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Type de diplôme",
        help: "C'est le dernier diplôme obtenu avant l'inscription",
        id: "last",
        name: "last",
        required: true,
        choices: [
          {
            label: "Aucun diplôme ni title professionnel",
            value: "Aucun diplôme ni title professionnel",
            data: "Aucun diplôme ni title professionnel",
          },
          {
            label: "Autre diplôme ou titre de niveau bac",
            value: "Autre diplôme ou titre de niveau bac",
            data: "Autre diplôme ou titre de niveau bac",
          },
          {
            label: "Autre diplôme ou titre de niveau bac +1 ou 2",
            value: "Autre diplôme ou titre de niveau bac +1 ou 2",
            data: "Autre diplôme ou titre de niveau bac +1 ou 2",
          },
          {
            label: "Autre diplôme ou titre de niveau bac +3 ou 4",
            value: "Autre diplôme ou titre de niveau bac +3 ou 4",
            data: "Autre diplôme ou titre de niveau bac +3 ou 4",
          },
          {
            label: "Autre diplôme ou titre de niveau bac +5 ou plus",
            value: "Autre diplôme ou titre de niveau bac +5 ou plus",
            data: "Autre diplôme ou titre de niveau bac +5 ou plus",
          },
        ],
        attr: {
          placeholder: "Sélectionner une valeur",
        },
        row_attr: {
          class: "col-lg-6",
        },
      },
    },
  ],
};
