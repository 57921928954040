<template>
  <div
    :class="
      'widget apprentice' +
      (currentTriad.id && currentTriad.id === triad.id ? ' -active' : '')
    "
    @click="goDashboard(triad.id, trainingCourse.id)"
    :style="'border: 2px solid ' + setTriadCardBackgroundColor(triad) + ';'"
  >
    <div
      v-tooltip="{
        title:
          $t('Tutor') +
          ' : ' +
          (triad.tutor ? triad.tutor.firstName : '') +
          ' ' +
          (triad.tutor ? triad.tutor.lastName : '') +
          '\n' +
          $t('Apprentice master') +
          ' : ' +
          (triad.apprenticeMaster ? triad.apprenticeMaster.firstName : '') +
          ' ' +
          (triad.apprenticeMaster ? triad.apprenticeMaster.lastName : ''),
      }"
      class="d-flex align-items-center"
    >
      <AvatarComponent
        :disablePicture="true"
        :user="triad.apprentice"
        profile="apprentice"
      />
      <div class="name">
        {{
          triad.apprentice && triad.apprentice.firstName
            ? triad.apprentice.firstName
            : ""
        }}
        {{
          triad.apprentice && triad.apprentice.lastName
            ? triad.apprentice.lastName
            : ""
        }}
      </div>
    </div>
    <div
      v-if="triad.tutor && user.id === triad.tutor.id"
      class="tutor__icon"
      v-tooltip="{
        title: $t('studea_manager.contract.also_tutor'),
      }"
    >
      T
    </div>
    <div
      v-if="triad.contractRuptureDate"
      class="position-absolute top-1 end-0 pe-2"
    >
      <FontAwesomeIcon
        v-tooltip="{
          title:
            $t('studea_manager.contract.contract_rupture_date') +
            format(triad.contractRuptureDate),
        }"
        class="text-danger"
        icon="fa-light fa-link-slash"
      />
    </div>
  </div>
</template>

<script>
import AvatarComponent from "@/components/utilities/AvatarComponent.vue";
import { mapState } from "pinia";
import { useUserStore } from "@/store/user/user";
import { useTriadStore } from "@/store/triad/triad";
import { dateFormatter } from "@/services/utilities/date-formatter";

export default {
  name: "TriadCardComponent",
  props: {
    triad: {
      type: {},
      required: true,
    },
    trainingCourse: {
      type: {},
      required: true,
    },
  },
  mounted() {},
  computed: {
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
    ...mapState(useTriadStore, {
      currentTriad: (store) => store.triad,
    }),
  },
  methods: {
    setTriadCardBackgroundColor(triad) {
      if (
        triad.activeContract &&
        triad.activeContract.contractType &&
        triad.activeContract.contractType.contractTypeColor
      ) {
        return triad.activeContract.contractType.contractTypeColor;
      }
    },

    goDashboard(triadId, trainingCourseId) {
      const oldTriad = window.localStorage.getItem("studea-oldTriad")
        ? parseInt(window.localStorage.getItem("studea-oldTriad"))
        : null;
      if (!this.triad.id || triadId !== oldTriad) {
        useTriadStore()
          .changeTriad({
            triadId: triadId,
            trainingCourseId: trainingCourseId,
          })
          .then(() => {
            this.$emit("triadLoaded");
            this.$router.push({ name: "Dashboard" });
          });
      } else {
        this.$emit("triadLoaded");
        this.$router.push({
          name: "Dashboard",
        });
      }
    },

    format(date) {
      return dateFormatter.format(date, "short");
    },
  },
  components: { AvatarComponent },
};
</script>

<style lang="scss" scoped>
.widget {
  &.apprentice {
    padding: 10px;
    display: inline-flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    height: 65px;

    @include mobile {
      width: 100%;
      margin-right: 0;
    }

    &.-active {
      border-color: $blue;
    }

    &:hover {
      background: $light-blue;
    }

    @include dark-theme {
      color: $white;

      &:hover {
        background: $m-color_9 !important;
      }
    }
  }
}
</style>
