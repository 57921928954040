<template>
  <div class="inscription">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-2">
          <div class="widget">
            <div class="widget-heading">
              <h2>Formation</h2>
            </div>
            <div class="widget-content">
              <p>Bachelor IT Fullstack avec une vision IA</p>
              <p>EPSI - Angers</p>
              <p>2024 - 2025</p>
            </div>
          </div>

          <div class="widget">
            <div class="widget-heading">
              <h2>Informations pédagogiques</h2>
            </div>
            <div class="widget-content"></div>
          </div>

          <div class="widget">
            <div class="widget-heading">
              <h2>Informations financières</h2>
            </div>
            <div class="widget-content"></div>
          </div>
        </div>
        <div class="col-lg-8">
          <InscriptionStepsComponent :steps="steps" :key="current" />
          <template v-for="(step, index) in steps" :key="step.code">
            <template v-if="index + 1 === current">
              <template v-for="block in step.blocks" :key="block.code">
                <div class="widget">
                  <div class="widget-heading">
                    <h2 class="textStyle">{{ block.label }}</h2>
                  </div>
                  <div class="widget-content">
                    <div
                      v-if="block.help"
                      class="alert alert-light-warning"
                      v-html="block.help"
                    ></div>
                    <InscriptionQuoteComponent v-if="block.code === 'quote'" />
                    <InscriptionDocumentsComponent
                      v-else-if="block.code === 'quote-documents'"
                    />
                    <FormComponent
                      :models="block.models"
                      :hideSubmit="true"
                      v-else
                    />
                  </div>
                </div>
              </template>
            </template>
          </template>
          <InscriptionControlsComponent :steps="steps" :current="current" />
        </div>
        <div class="col-lg-2">
          <div class="widget">
            <div class="widget-heading">
              <h2>Actions à réaliser</h2>
            </div>
            <div class="widget-content"></div>
          </div>
          <div class="widget">
            <div class="widget-heading">
              <h2>Aide</h2>
            </div>
            <div class="widget-content"></div>
          </div>
          <InscriptionFollowComponent />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { useMainStore } from "@/store/main/main";
import FormComponent from "@/components/FormComponent.vue";
import steps from "@/model/inscription/steps";
import InscriptionStepsComponent from "@/components/inscription/InscriptionStepsComponent.vue";
import InscriptionQuoteComponent from "@/components/inscription/InscriptionQuoteComponent.vue";
import InscriptionDocumentsComponent from "@/components/inscription/InscriptionDocumentsComponent.vue";
import InscriptionControlsComponent from "@/components/inscription/InscriptionControlsComponent.vue";
import InscriptionFollowComponent from "@/components/inscription/InscriptionFollowComponent.vue";

export default {
  name: "PreInscriptionSheetView",
  components: {
    InscriptionFollowComponent,
    InscriptionControlsComponent,
    InscriptionDocumentsComponent,
    InscriptionQuoteComponent,
    InscriptionStepsComponent,
    FormComponent,
  },
  data() {
    return {
      steps: steps,
      current: parseInt(this.$route.params.step),
    };
  },
  watch: {
    $route() {
      this.current = parseInt(this.$route.params.step);
    },
  },
  computed: {},
  created() {},
  mounted() {
    this.init();
    this.setAppLoading(false);
  },
  methods: {
    init() {},
    ...mapActions(useMainStore, ["setAppLoading"]),
  },
};
</script>

<style lang="scss" scoped>
.inscription {
  --inscription-success: #6ac7a4;
  --inscription-progress: #f8a007;
  --inscription-waiting: #888;
  --secondary-color: #888;
}
</style>
