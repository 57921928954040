<template>
  <div class="container-fluid">
    <ErrorView v-if="errorStatus" />
    <router-view v-else></router-view>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useErrorStore } from "@/store/error/error";
import ErrorView from "@/views/Error/ErrorView.vue";
import { colorManager } from "@/services/platform/color-manager";

export default {
  name: "LayoutView",
  components: { ErrorView },
  computed: {
    ...mapState(useErrorStore, {
      errorStatus: (store) => store.status,
    }),
  },
  mounted() {
    colorManager.setPlatformStyle();
  },
};
</script>

<style lang="scss" scoped></style>
