export default [
  {
    titlePart1: "Date de création",
    date: "06/07/2023 15:11:00",
    id: 1,
    icon: "timer",
    type: "success",
    creator: "Pierre DURAND",
  },
  {
    titlePart1: "Date de validation",
    date: "06/07/2023 15:11:00",
    id: 1,
    icon: "check",
    type: "success",
    creator: "Pierre DURAND",
  },
  {
    titlePart1: "Date de dernière modification",
    date: "06/07/2023 15:11:00",
    id: 1,
    icon: "pen",
    type: "warning",
    creator: "Pierre DURAND",
  },
];
