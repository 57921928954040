export const companyContractRegistrationBody = {
  label: "Organisme d'enregistrement du contrat",
  code: "contract_registration_body",
  models: [
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Organisme financeur (OPCO, CNFPT, employeur public)",
        id: "funds",
        name: "funds",
        required: true,
        choices: [
          {
            label: "ATLAS",
            value: "ATLAS",
            data: "ATLAS",
          },
          {
            label: "OPCO",
            value: "OPCO",
            data: "OPCO",
          },
        ],
        attr: {
          placeholder: "Sélectionner un organisme",
        },
        row_attr: {
          class: "col-lg-6",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Convention collective (IDCC) ",
        id: "idcc",
        name: "idcc",
        required: true,
        help: "Si pas de convention collective ou structures publiques : 9999",
        choices: [
          {
            label:
              "1486 - Convention collective national applicable au personnel des bureaux d'études techniques, des ...",
            value:
              "1486 - Convention collective national applicable au personnel des bureaux d'études techniques, des ...",
            data: "1486 - Convention collective national applicable au personnel des bureaux d'études techniques, des ...",
          },
          {
            label: "9999",
            value: "9999",
            data: "9999",
          },
        ],
        attr: {
          placeholder: "Sélectionner une convention",
        },
        row_attr: {
          class: "col-lg-6",
        },
      },
    },
  ],
};
