<template>
  <div class="widget">
    <div class="widget-heading align-items-center">
      <div>
        <h2 class="textStyle h-auto">Historique</h2>
      </div>
    </div>
    <div class="widget-content">
      <div class="item">
        <span class="fw-bold">Date de création : </span>06/07/2023 à 15h11 par
        Pierre DURAND
      </div>
      <div class="item">
        <span class="fw-bold">Date de validation : </span>06/07/2023 à 15h11 par
        Pierre DURAND
      </div>
      <div class="item">
        <span class="fw-bold">Date de dernière modification : </span>06/07/2023
        à 15h11 par Pierre DURAND
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContractHistoryBlockComponent",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.item {
  border-bottom: 1px dashed $m_color_5;
  margin-bottom: 2px;
  padding-bottom: 2px;
  font-size: 13px;

  &:last-child {
    border: none;
  }
}
</style>
