<template>
  <div class="table-light table-responsive">
    <table
      role="table"
      aria-busy="false"
      aria-colcount="5"
      class="table b-table table-hover table-striped table-bordered"
      id="__BVID__310"
    >
      <thead role="rowgroup" class="">
        <tr role="row" class="">
          <th role="columnheader" scope="col" aria-colindex="1" class="">
            <div></div>
          </th>
          <th role="columnheader" scope="col" aria-colindex="2" class="">
            <div>Du</div>
          </th>
          <th role="columnheader" scope="col" aria-colindex="3" class="">
            <div>Au</div>
          </th>
          <th role="columnheader" scope="col" aria-colindex="4" class="">
            <div>% du</div>
          </th>
          <th role="columnheader" scope="col" aria-colindex="5" class="">
            <div>SMC/SMIC</div>
          </th>
          <th role="columnheader" scope="col" aria-colindex="6" class="">
            <div>Du</div>
          </th>
          <th role="columnheader" scope="col" aria-colindex="7" class="">
            <div>Au</div>
          </th>
          <th role="columnheader" scope="col" aria-colindex="8" class="">
            <div>% du</div>
          </th>
          <th role="columnheader" scope="col" aria-colindex="9" class="">
            <div>SMC/SMIC</div>
          </th>
        </tr>
      </thead>
      <tbody role="rowgroup">
        <tr role="row" class="">
          <td aria-colindex="1" role="cell" class="">Année 1</td>
          <td aria-colindex="2" role="cell" class="">15/07/2024</td>
          <td aria-colindex="3" role="cell" class="">14/07/2025</td>
          <td aria-colindex="4" role="cell" class="">53</td>
          <td aria-colindex="5" role="cell" class="">SMIC</td>
          <td aria-colindex="6" role="cell" class=""></td>
          <td aria-colindex="7" role="cell" class=""></td>
          <td aria-colindex="8" role="cell" class=""></td>
          <td aria-colindex="9" role="cell" class=""></td>
        </tr>

        <tr role="row" class="">
          <td aria-colindex="1" role="cell" class="">Année 2</td>
          <td aria-colindex="2" role="cell" class="">15/07/2024</td>
          <td aria-colindex="3" role="cell" class="">08/09/2025</td>
          <td aria-colindex="4" role="cell" class="">53</td>
          <td aria-colindex="5" role="cell" class="">SMIC</td>
          <td aria-colindex="6" role="cell" class=""></td>
          <td aria-colindex="7" role="cell" class=""></td>
          <td aria-colindex="8" role="cell" class=""></td>
          <td aria-colindex="9" role="cell" class=""></td>
        </tr>

        <tr role="row" class="">
          <td aria-colindex="1" role="cell" class="">Année 3</td>
          <td aria-colindex="2" role="cell" class=""></td>
          <td aria-colindex="3" role="cell" class=""></td>
          <td aria-colindex="4" role="cell" class=""></td>
          <td aria-colindex="5" role="cell" class=""></td>
          <td aria-colindex="6" role="cell" class=""></td>
          <td aria-colindex="7" role="cell" class=""></td>
          <td aria-colindex="8" role="cell" class=""></td>
          <td aria-colindex="9" role="cell" class=""></td>
        </tr>

        <tr role="row" class="">
          <td aria-colindex="1" role="cell" class="">Année 4</td>
          <td aria-colindex="2" role="cell" class=""></td>
          <td aria-colindex="3" role="cell" class=""></td>
          <td aria-colindex="4" role="cell" class=""></td>
          <td aria-colindex="5" role="cell" class=""></td>
          <td aria-colindex="6" role="cell" class=""></td>
          <td aria-colindex="7" role="cell" class=""></td>
          <td aria-colindex="8" role="cell" class=""></td>
          <td aria-colindex="9" role="cell" class=""></td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row mb-2 align-items-center">
    <div class="col-lg-3">
      <span class="fw-bold">Tarif de la formation</span>
    </div>
    <div class="col-lg-3 text-end">
      <h2 class="badge bg-success text-white">9916,67 €</h2>
    </div>
  </div>

  <div class="row mb-2 align-items-center">
    <div class="col-lg-3">
      <span class="fw-bold">Montant de la prise en charge OPCO</span>
    </div>
    <div class="col-lg-3 text-end">
      <h2 class="badge bg-success text-white">8680,00 €</h2>
    </div>
  </div>

  <div class="row align-items-center">
    <div class="col-lg-3">
      <span class="fw-bold">Reste à charge</span>
    </div>
    <div class="col-lg-3 text-end">
      <h2 class="badge bg-success text-white">1236,67 €</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "InscriptionCompensationTableComponent",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
h2.badge {
  font-size: 16px;
}
</style>
