<template>
  <div class="quote">
    <div class="quote__part">
      <div class="row">
        <div class="col-lg-6">
          <h6>Supalia</h6>
          <p>4 rue Eugène Renault<br />94700 MAISON ALFORT</p>
        </div>

        <div class="col-lg-6">
          <p>Etablissement d’exécution du contrat</p>
          <h6>ORANGE BUSINESS SERVICES</h6>
          <p>
            17 19 Rue Victor Basch<br />
            91300 MASSY<br />
            SIRET : 34503941600119<br />
            IDCC : 1486<br />
            Caisse complémentaire : AGIRC ARRCO<br />
            Organisme financeur : ATLAS<br />
          </p>
        </div>
      </div>
    </div>

    <div class="quote__part">
      <div class="row">
        <div class="col-lg-6">
          <p>Date d'émission du devis : 14/01/2025</p>
          <p>N° du devis : D202537667</p>
        </div>

        <div class="col-lg-6"></div>
      </div>
    </div>

    <div class="quote__part">
      <div class="row">
        <div class="col-lg-6">
          <h6>Gestionnaire contrat</h6>
          <p>Seynabou SAMBA</p>
          <p>Email : gestion@supalia.fr</p>
          <p>Tél : 01 43 53 68 00</p>
          <h6>Signataire du contrat :</h6>
          <p>RAZANAJATOVO Michele</p>
          <p>Email : stagesalternances.obssa@orange.com</p>
          <p>Tél : 07 85 30 83 10</p>
        </div>

        <div class="col-lg-6">
          <h6>Chargé de relations partenaires</h6>
          <p>Mélanie GALLAIS</p>
          <p>Email : melanie.gallais@supalia.fr</p>
          <p>Tél : 01 43 53 65 33</p>
          <h6>Interlocuteur.rice pour la gestion administrative :</h6>
          <p>RAZANAJATOVO Michele</p>
          <p>Email : stagesalternances.obssa@orange.com</p>
          <p>Tél : 07 85 30 83 10</p>
          <h6>Siège social</h6>
          <p>SIRET : 34503941600085</p>
          <p>1 Place Des Droits De L'homme, 93200 ST DENIS</p>
        </div>
      </div>
    </div>

    <div class="quote__part">
      <div class="row">
        <div class="col-lg-12">
          <h6>Apprenti.e</h6>
          <p>NSANGUE Nathan (20 ans)</p>
          <p>E-Mail : nsanguenathan@gmail.com</p>
          <p>Tél : 07 68 33 88 53</p>

          <h6>Diplôme</h6>
          <p>
            LP MRIT ASSR 1an VIL 24-25 - LP Métiers des Réseaux Informatiques et
            Télécommunications Option ASSR 1an VILLETANEUSE 2024-2025
          </p>
          <p>Code diplôme : 2503263F</p>
          <p>Dates de la formation : du 09/09/2024 au 08/09/2025</p>
          <p>Code RNCP : RNCP29968</p>
          <p>Etablissement : Site de Villetaneuse (IUT)</p>
          <p>Durée totale de la formation : 452 heures</p>
          <p>Rythme d'alternance CFA / Entreprise : 3 sem/2 sem</p>
          <h6>Contrat : Du 15/07/2024 au 08/09/2025</h6>
          <p>Durée hebdomadaire prévue : 35h00</p>
          <h6>
            Vous souhaitez donner mandat au CFA pour transmettre le contrat et
            la convention finalisés auprès de l’organisme d’enregistrement
            (OPCO, DREETS) ?
          </h6>
          <p>Oui</p>
          <h6>Vous souhaitez que le CFA vous transmette</h6>
          <p>CERFA ET CONVENTION</p>
        </div>
      </div>
    </div>

    <div>
      <h4>Rémunération</h4>
      <div class="alert alert-light-warning text-center">
        Attention à bien prendre en compte le niveau d'études, l'âge et le
        cursus de l'apprenti.e
      </div>
      <div class="row mb-3">
        <div class="col-lg-4 mb-1">
          <span class="fw-bold">Heures mensuelles</span> : 151.67 heures
        </div>
        <div class="col-lg-4">
          <span class="fw-bold">Salaire minimum légal mensuel</span> : 1801,80€
        </div>
        <div class="col-lg-4">
          <span class="fw-bold">Salaire minimum légal horaire</span> : 11,88€
        </div>
        <div class="col-lg-4">
          <span class="fw-bold">Base légale hebdomadaire</span> : 35h00
        </div>
        <div class="col-lg-4">
          <span class="fw-bold">Salaire brut mensuel à l'embauche</span> :
          936,46€
        </div>
      </div>

      <InscriptionCompensationTableComponent />
    </div>
  </div>
</template>

<script>
import InscriptionCompensationTableComponent from "@/components/inscription/InscriptionCompensationTableComponent.vue";

export default {
  name: "InscriptionQuoteComponent",
  components: { InscriptionCompensationTableComponent },
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.quote {
  h6 {
    margin: 15px 0;
  }

  &__part {
    border-bottom: 1px dashed $m_color_6;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
}
</style>
