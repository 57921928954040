<template>
  <div class="category__line">
    <span v-if="!copy" v-tooltip="{ title: info(questionnaire) }" class="info">
      <FontAwesomeIcon icon="fa-light fa-circle-info" />
    </span>
    <span class="tree" v-else></span>
    <router-link
      v-if="
        !questionnaire.canBeCopiedBy ||
        (questionnaire.canBeCopiedBy && !questionnaire.canBeCopiedBy.length) ||
        questionnaire.isCopy
      "
      :to="{
        name: 'questionnaire',
        params: {
          questionnaireId: questionnaire.id,
          triadId: triad.id,
        },
      }"
      class="category__title"
      :class="{ copy: copy }"
    >
      {{ questionnaire.title }}
      <button
        v-if="canRemove(questionnaire)"
        class="btn btn-icon trash"
        @click="removeQuestionnaire($event, questionnaire.id)"
      >
        <FontAwesomeIcon class="ms-2" icon="fa-light fa-trash" />
      </button>
    </router-link>
    <button
      v-else
      class="category__title -canBeCopiedBy"
      @click="copyQuestionnaire(questionnaire.id)"
      :disabled="!questionnaire.canBeCopiedBy.includes(this.triad.userRoles[0])"
    >
      {{ questionnaire.title }}
      <FontAwesomeIcon class="ms-2" icon="fa-light fa-plus" />
    </button>
    <div
      v-if="
        !hideIcons &&
        (!questionnaire.canBeCopiedBy ||
          (questionnaire.canBeCopiedBy && !questionnaire.canBeCopiedBy.length))
      "
      class="category__roles"
    >
      <button
        v-if="questionnaire.profileStatus.apprentice"
        :key="key"
        :disabled="!canManage(questionnaire.profileStatus.apprentice)"
        :class="
          'category__role textStyle ' +
          (canManage(questionnaire.profileStatus.apprentice)
            ? 'icon-secondary-color'
            : '')
        "
        v-tooltip="{
          title: this.initTooltip(questionnaire.profileStatus.apprentice),
        }"
        @click="
          manage(questionnaire.profileStatus.apprentice, 1, questionnaire.id)
        "
      >
        <FontAwesomeIcon
          :icon="'fa fa-' + questionnaire.profileStatus.apprentice.icon"
        />
      </button>
      <button
        v-if="questionnaire.profileStatus.tutor"
        :disabled="!canManage(questionnaire.profileStatus.tutor)"
        :key="key"
        :class="
          'category__role textStyle ' +
          (canManage(questionnaire.profileStatus.tutor)
            ? 'icon-secondary-color'
            : '')
        "
        v-tooltip="{
          title: this.initTooltip(questionnaire.profileStatus.tutor),
        }"
        @click="manage(questionnaire.profileStatus.tutor, 2, questionnaire.id)"
      >
        <FontAwesomeIcon
          :icon="'fa fa-' + questionnaire.profileStatus.tutor.icon"
        />
      </button>
      <button
        v-if="questionnaire.profileStatus.apprenticeMaster"
        :disabled="!canManage(questionnaire.profileStatus.apprenticeMaster)"
        :key="key"
        :class="
          'category__role textStyle ' +
          (canManage(questionnaire.profileStatus.apprenticeMaster)
            ? 'icon-secondary-color'
            : '')
        "
        v-tooltip="{
          title: this.initTooltip(questionnaire.profileStatus.apprenticeMaster),
        }"
        @click="
          manage(
            questionnaire.profileStatus.apprenticeMaster,
            3,
            questionnaire.id,
          )
        "
      >
        <FontAwesomeIcon
          :icon="'fa fa-' + questionnaire.profileStatus.apprenticeMaster.icon"
        />
      </button>
      <button
        v-if="
          triad.studeaManagerColumn && questionnaire.profileStatus.studeaManager
        "
        :key="key"
        :disabled="!canManage(questionnaire.profileStatus.studeaManager)"
        @click="
          manage(questionnaire.profileStatus.studeaManager, 4, questionnaire.id)
        "
        :class="
          'category__role textStyle ' +
          (canManage(questionnaire.profileStatus.studeaManager)
            ? 'icon-secondary-color'
            : '')
        "
        v-tooltip="{
          title: this.initTooltip(questionnaire.profileStatus.studeaManager),
        }"
      >
        <FontAwesomeIcon
          :icon="'fa fa-' + questionnaire.profileStatus.studeaManager.icon"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useTriadStore } from "@/store/triad/triad";
import { useUserStore } from "@/store/user/user";
import { dateFormatter } from "@/services/utilities/date-formatter";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import { useTrainingCourseStore } from "@/store/training-course/training-course";

export default {
  name: "QuestionnaireListRowComponent",
  props: {
    questionnaire: {
      type: Object,
      required: true,
    },
    copy: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideIcons: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      key: 0,
    };
  },
  computed: {
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
    ...mapState(useTrainingCourseStore, {
      trainigCourse: (store) => store.trainingCourse,
    }),
  },
  methods: {
    formatDate(date) {
      return dateFormatter.format(date, "short");
    },

    canManage(status) {
      const expired = new Date().getTime() > new Date(status.endDate).getTime();
      return (
        ((status.icon === "lock" && expired && !status.before) ||
          status.icon === "check" ||
          status.icon === "hourglass-start") &&
        ((this.user.roles && this.user.roles.includes("ROLE_STUDEA_ADMIN")) ||
          (this.trainingCourse && this.trainingCourse.isStudeaManager))
      );
    },

    info(questionnaire) {
      let ret = "";
      let breakline = false;
      const userRoles = this.triad.userRoles;
      const displayAll =
        userRoles.includes(4) ||
        (this.user.roles && this.user.roles.includes("ROLE_STUDEA_ADMIN"));
      if (
        questionnaire.signedBy.includes(1) &&
        (displayAll || userRoles.includes(1)) &&
        questionnaire.profileStatus.apprentice
      ) {
        ret = ret.concat(
          "A : " +
            this.$t("to_be_completed_from") +
            this.formatDate(questionnaire.profileStatus.apprentice.startDate) +
            this.$t("to") +
            this.formatDate(
              questionnaire.completenessDate
                ? questionnaire.completenessDate
                : questionnaire.profileStatus.apprentice.endDate,
            ),
        );
        breakline = true;
      }
      if (
        questionnaire.signedBy.includes(2) &&
        (displayAll || userRoles.includes(2)) &&
        questionnaire.profileStatus.tutor
      ) {
        if (breakline) {
          ret = ret.concat("\n");
        }
        ret = ret.concat(
          "T : " +
            this.$t("to_be_completed_from") +
            this.formatDate(questionnaire.profileStatus.tutor.startDate) +
            this.$t("to") +
            this.formatDate(
              questionnaire.completenessDate
                ? questionnaire.completenessDate
                : questionnaire.profileStatus.tutor.endDate,
            ),
        );
        breakline = true;
      }
      if (
        questionnaire.signedBy.includes(3) &&
        (displayAll || userRoles.includes(3)) &&
        questionnaire.profileStatus.apprenticeMaster
      ) {
        if (breakline) {
          ret = ret.concat("\n");
        }
        ret = ret.concat(
          "M : " +
            this.$t("to_be_completed_from") +
            this.formatDate(
              questionnaire.profileStatus.apprenticeMaster.startDate,
            ) +
            this.$t("to") +
            this.formatDate(
              questionnaire.completenessDate
                ? questionnaire.completenessDate
                : questionnaire.profileStatus.apprenticeMaster.endDate,
            ),
        );
      }
      return ret;
    },

    manage(status, profile, id) {
      const manageType = this.setManageType(status);

      switch (manageType) {
        case studeaManagerManager.STUDEA_MANAGER_UNLOCK_QUESTIONNAIRE:
          this.unlockReply(status, profile, id, this.triad);
          break;
        case studeaManagerManager.STUDEA_MANAGER_SEND_REMINDER:
          this.sendEmailReminder(this.triad, profile, id);
          break;
      }
    },

    setManageType(status) {
      const expired = new Date().getTime() > new Date(status.endDate).getTime();

      if (status.icon === "check" || (status.icon === "lock" && expired)) {
        return studeaManagerManager.STUDEA_MANAGER_UNLOCK_QUESTIONNAIRE;
      } else if (status.icon === "hourglass-start") {
        return studeaManagerManager.STUDEA_MANAGER_SEND_REMINDER;
      }
    },

    unlockReply(status, profile, id, triad) {
      studeaManagerManager.unlockReply(profile, id, triad.id).then(() => {
        if (status.icon === "lock") {
          status.icon = "lock-open";
        }
        if (status.icon === "check") {
          status.icon = "hourglass-start";
        }
        this.key++;
      });
    },

    sendEmailReminder(triad, profile, id) {
      let data = {
        triad: [{ id: this.triad.id }],
        questionnaire: [{ id: id }],
        userType: [{ id: profile }],
        emailContent: null,
      };

      studeaManagerManager.sendOneEmailReminder(data, triad.trainingCourse.id);
    },

    initTooltip(questionnaireProfile) {
      if (this.canManage(questionnaireProfile)) {
        if (questionnaireProfile.icon === "check") {
          return this.$t(
            "studea_manager.dashboard.questionnaire.cancel_signature",
          );
        } else if (questionnaireProfile.icon === "hourglass-start") {
          return this.$t(
            "studea_manager.dashboard.questionnaire.send_email_reminder",
          );
        } else {
          return this.$t(
            "studea_manager.dashboard.questionnaire.unlock_questionnaire",
          );
        }
      } else if (questionnaireProfile.before) {
        return (
          this.$t("open_at") + this.formatDate(questionnaireProfile.startDate)
        );
      } else {
        return "";
      }
    },

    copyQuestionnaire(id) {
      this.$emit("copyQuestionnaire", id);
    },

    removeQuestionnaire(e, id) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit("removeQuestionnaire", id);
    },

    canRemove(questionnaire) {
      let signedOrShared = false;

      signedOrShared = !!Object.values(questionnaire.lastReply).find(
        (profile) => profile.status > 1,
      );

      return (
        questionnaire.isCopy &&
        questionnaire.copiedBy &&
        this.user &&
        this.user.id &&
        questionnaire.copiedBy.id === this.user.id &&
        !signedOrShared
      );
    },
  },
};
</script>

<style scoped lang="scss">
.category {
  @include up-to-md {
    font-size: 12px;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    background: $purple;
    color: $white;
    border-radius: $radius;
    text-transform: uppercase;
    font-weight: 700;
    overflow: hidden;
  }

  &__line {
    display: flex;
    color: $blue;

    @include dark-theme {
      color: $white;
    }
  }

  &__roles {
    display: flex;
    width: 25%;
    background: $white;
    justify-content: space-between;

    @include dark-theme {
      background: $m-color_10;
    }

    @include up-to-xl {
      width: 30%;
    }

    @include up-to-lg {
      width: 40%;
    }

    &.-heading {
      background: $blue;

      @include dark-theme {
        background: $blue;
      }
    }
  }

  &__role {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: none;
    padding: 0;
    border: none;

    svg {
      font-size: 16px;
    }

    .fa-check {
      color: $green;
    }
  }

  &__title {
    width: 80%;
    padding: 10px;
    font-weight: 300;
    background: none;
    border: none;
    display: inline;
    color: $m-color_9;
    text-align: left;

    &.-canBeCopiedBy {
      text-decoration: underline;
    }

    &.copy {
      font-style: italic;
      padding-left: 20px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.secondary-color {
  background-color: var(--secondary-color) !important;
}

.info {
  padding: 10px 0 10px 5px;
}

.icon-secondary-color {
  color: var(--secondary-color) !important;
}

.trash {
  color: $m-color_9;

  svg {
    width: 14px;
  }

  &:hover,
  &:active,
  &:focus {
    background: none;
  }
}

.tree {
  border-left: 1px solid var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
  width: 15px;
  height: 15px;
  border-radius: 0 0 0 3px;
  position: relative;
  top: 3px;
  left: 11px;
}
</style>
