<template>
  <div id="timeline-block" class="widget widget-active-log">
    <div class="widget-heading">
      <h2 class="textStyle">Suivi du dossier</h2>
    </div>

    <div class="widget-content">
      <div class="w-shadow-top"></div>
      <div class="mt-container mx-auto mb-2">
        <div class="timeline-line">
          <div v-for="item in datas" :key="item.id" class="item-timeline">
            <div class="t-dot">
              <div :class="'bg-' + item.type">
                <FontAwesomeIcon :icon="'fa-light fa-' + item.icon" />
              </div>
            </div>
            <div class="t-content">
              <div class="t-uppercontent">
                <h5 class="mb-0">{{ item.titlePart1 }}</h5>
              </div>
              <p v-if="item.date">{{ format(item.date) }}</p>
              <p>{{ item.creator }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormatter } from "@/services/utilities/date-formatter";

export default {
  name: "ContractFollowBlockComponent",
  components: {},
  props: {
    datas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    format(date) {
      return dateFormatter.format(date, "short_time");
    },
  },
};
</script>

<style lang="scss" scoped>
.mt-container {
  overflow: hidden;
  height: auto !important;
  max-height: 360px;
  transition: all 300ms ease-in-out;

  &.all {
    max-height: 5000px;
  }
}

.widget-button {
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);

  &:hover {
    background: var(--secondary-color);
  }
}

.widget-plus {
  &.expanded {
    .widget-button {
      transform: rotate(45deg);
    }
  }
}
</style>
