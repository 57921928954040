export const companyCompanyInformations = {
  label: "Etablissement d'exécution du contrat",
  code: "company_infos",
  help: "Si l'adresse du site d'accueil de l’alternant.e est différente de l'adresse de l'entreprise d'accueil et n'a pas de SIRET, merci de cocher la case.",
  models: [
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Numéro SIRET",
        id: "siret",
        name: "siret",
        help: "Le siret doit correspondre à l'adresse du lieu d'exécution du contrat",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Type d'employeur",
        id: "company_type",
        name: "company_type",
        required: true,
        choices: [
          {
            label:
              "Entreprise inscrite uniquement au registre du commerce et des métiers",
            value:
              "Entreprise inscrite uniquement au registre du commerce et des métiers",
            data: "Entreprise inscrite uniquement au registre du commerce et des métiers",
          },
        ],
        attr: {
          placeholder: "Sélectionner un type",
        },
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Raison sociale",
        id: "social_reason",
        name: "social_reason",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Code NAF",
        id: "naf_code",
        name: "naf_code",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Caisse retraite complémentaire",
        id: "supplementary_pension_fund",
        name: "supplementary_pension_fund",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Effectif total",
        help: "L’effectif doit correspondre à l’effectif de l’ensemble des établissements rattachés au groupe (exemple : plusieurs agences)",
        id: "total_workforce",
        name: "total_workforce",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "choice"],
        label: "Employeur spécifique",
        id: "specific_company",
        name: "specific_company",
        required: true,
        choices: [
          {
            label: "Aucun de ces cas",
            value: "Aucun de ces cas",
            data: "Aucun de ces cas",
          },
        ],
        attr: {
          placeholder: "Sélectionner un type",
        },
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "title"],
        label: "Adresse",
        separator: true,
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Adresse",
        id: "company_address",
        name: "company_address",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Complément",
        id: "company_complement",
        name: "company_complement",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "text"],
        label: "Commune",
        id: "city",
        name: "city",
        required: true,
        row_attr: {
          class: "col-lg-4",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "switch"],
        label:
          "L'adresse du site d'accueil de l'alternant(e) est différente de l'adresse de l'entreprise d'accueil",
        id: "same_address",
        name: "same_address",
      },
    },
  ],
};
