<template>
  <div class="widget">
    <div class="widget-heading align-items-center">
      <div>
        <h2 class="textStyle h-auto">Alternant</h2>
        <p class="mb-0">Franck BAUR</p>
      </div>
      <router-link
        :to="{ name: 'TrainingPreInscription', params: { step: 1 } }"
        class="btn-icon"
      >
        <FontAwesomeIcon icon="fa-light fa-pen" />
      </router-link>
    </div>
    <div class="widget-content">
      <div class="row">
        <div class="col-lg-6">
          <h6>Informations générales</h6>
          <div class="item"><span class="fw-bold">Civilité :</span> M.</div>
          <div class="item"><span class="fw-bold">Nom :</span> BAUR</div>
          <div class="item"><span class="fw-bold">Prénom :</span> Franck</div>
          <div class="item">
            <span class="fw-bold">Date de naissance :</span> 06/05/1998
          </div>
          <div class="item"><span class="fw-bold">Âge :</span> 25 ans</div>
          <div class="item">
            <span class="fw-bold">Email :</span> baur.franck@gmail.com
          </div>
          <div class="item">
            <span class="fw-bold">Téléphone :</span> 07 50 83 52 78
          </div>
          <div class="item">
            <span class="fw-bold">Adresse :</span> 67 av. Jean Jaurès 69007 LYON
            07
          </div>
          <div class="item">
            <span class="fw-bold">Nationalité :</span> 1-Française
          </div>
        </div>

        <div class="col-lg-6">
          <h6>Situation antérieur</h6>
          <div class="item">Code INE :</div>
          <div class="item">
            <span class="fw-bold">Situation avant contrat :</span><br />
            7-Inscrit sans contrat sous statut SFP
          </div>
          <div class="item">
            <span class="fw-bold">Dernier diplôme obtenu :</span> 69-Licence
            générale
          </div>
          <div class="item">
            <span class="fw-bold"> Intitulé :</span><br />
            Licence marketing stratégique et opérationnel
          </div>
          <div class="item">
            <span class="fw-bold"> Dernier diplôme préparé :</span><br />
            71-Master professionnel
          </div>
          <div class="item">
            <span class="fw-bold"> Intitulé :</span><br />
            Master marketing stratégique et opérationnel
          </div>
          <div class="item">
            <span class="fw-bold"> Dernier établissement :</span><br />
            Lycée agricole de la Seine sur mer
          </div>
          <div class="item">
            <span class="fw-bold">Code UAI :</span> 252664M
          </div>
          <div class="item"><span class="fw-bold">Département :</span> 25</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContractApprenticeBlockComponent",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.item {
  border-bottom: 1px dashed $m_color_5;
  margin-bottom: 2px;
  padding-bottom: 2px;
  font-size: 13px;

  &:last-child {
    border: none;
  }
}
</style>
