export const missionsMissions = {
  label: "Missions",
  code: "missions",
  models: [
    {
      vars: {
        block_prefixes: ["", "text", "textarea"],
        label: "Décrivez les missions de l'alternant.e",
        id: "missions",
        name: "missions",
        required: true,
        attr: {
          class: "textarea-xl",
        },
        row_attr: {
          class: "col-lg-12",
        },
      },
    },
    {
      vars: {
        block_prefixes: ["", "file"],
        label: "Utiliser une pièce jointe",
        id: "missions_file",
        name: "missions_file",
        required: true,
        row_attr: {
          class: "col-lg-12",
        },
      },
    },
  ],
};
