<template>
  <div class="widget">
    <div class="widget-heading">
      <h2 class="textStyle">Contacts</h2>
    </div>
    <div class="widget-content">
      <div class="contract__part">
        <h6>Apprenti</h6>
        <div>Franck BAUR</div>
      </div>

      <div class="contract__part">
        <h6>Tuteur</h6>
        <div>Pierre DURAND</div>
      </div>

      <div class="contract__part">
        <h6>Maitre d'apprentissage</h6>
        <div>Etienne DURAND</div>
        <div>Date de naissance : 21/05/1986</div>
        <div>Fonction : HRBP</div>
        <div>Diplôme : 71-Master</div>
        <div>Intitulé :</div>
      </div>

      <div class="contract__part">
        <h6>Maitre d'apprentissage 2</h6>
        <div>Etienne DURAND</div>
        <div>Date de naissance : 21/05/1986</div>
        <div>Fonction : HRBP</div>
        <div>Diplôme : 71-Master</div>
        <div>Intitulé :</div>
      </div>

      <div class="contract__part">
        <h6>Contacts administratif</h6>
        <div>Philippe BENOLLET</div>
        <div>Pierre DURAND</div>
      </div>

      <div class="contract__part">
        <h6>Contacts entreprise</h6>
        <div>Philippe BENOLLET</div>
        <div>Pierre DURAND</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContractContactBlockComponent",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.contract {
  h6 {
    font-size: 15px;
  }

  &__part {
    border-bottom: 1px dashed $m_color_6;
    margin-bottom: 15px;
    padding-bottom: 15px;
    font-size: 13px;

    &:last-child {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}
</style>
