<template>
  <div
    ref="triadListModal"
    aria-hidden="true"
    aria-labelledby="triadListModal"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title textStyle">
            {{ $t("triadList") }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            data-dismiss="modal"
            type="button"
          ></button>
        </div>
        <div class="modal-body" v-if="trainingCourse && trainingCourse.crews">
          <TriadUsersListComponent
            :trainingCourse="trainingCourse"
            :search="true"
          />
        </div>
        <BlockLoader :loading="!trainingCourse || !trainingCourse.crews" />
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import TriadUsersListComponent from "@/components/triad/TriadUsersListComponent.vue";
import BlockLoader from "@/components/blocks/BlockLoader.vue";

export default {
  name: "TriadListModalComponent",
  components: { BlockLoader, TriadUsersListComponent },
  props: {
    trainingCourse: {
      type: Object,
      required: true,
    },
    opened: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {};
  },
  watch: {
    opened() {
      const container = this.$refs.triadListModal;
      const modal = new Modal(container);
      if (this.opened) {
        modal.show();
      } else {
        modal.hide();
        this.$emit("hidden");
      }
    },
  },
  mounted() {
    this.$refs.triadListModal.addEventListener("hidden.bs.modal", this.hidden);
  },
  methods: {
    hidden() {
      this.$emit("hidden");
      const container = this.$refs.triadListModal;
      container.removeEventListener("click", this.stop);
    },

    stop(e) {
      e.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  &.apprentice {
    padding: 10px;
    display: inline-flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    height: 65px;

    @include mobile {
      width: 100%;
      margin-right: 0;
    }

    &.-active {
      border-color: $blue;
    }

    &:hover {
      background: $light-blue;
    }

    @include dark-theme {
      color: $white;

      &:hover {
        background: $m-color_9 !important;
      }
    }
  }

  .name {
    margin-left: 5px;
  }

  .card {
    order: 2;

    &.-first {
      order: 1;

      .card-header {
        background: $light-blue;

        @include dark-theme {
          background: $m-color_18;
          color: $white;
        }
      }
    }

    .card-body {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.filter {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #e0e6ed;
}

.crew {
  border-bottom: 1px dashed $m-color_6;
  margin-bottom: 10px;

  &:last-child {
    border-bottom: none;
  }

  &__title {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
}

.tutor__icon {
  margin-left: 10px;
  font-size: 12px;
  padding: 1px 5px;
  border-radius: 6px;
  color: $primary;
  background-color: #f5f5fe;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
</style>
