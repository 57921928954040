export default [
  {
    label: "Candidature",
    icon: "user",
    valid: true,
    progress: false,
    validatedAt: "2025-01-14 11:50:00",
    validatedBy: "Brice Malafosse",
  },
  {
    label: "Pré-recrutement",
    icon: "folder-user",
    valid: false,
    progress: true,
    validatedAt: null,
    validatedBy: null,
  },
  {
    label: "Validation entreprise",
    icon: "building",
    valid: false,
    validatedAt: null,
    validatedBy: null,
  },
  {
    label: "Validation des missions",
    icon: "bullseye",
    valid: false,
    validatedAt: null,
    validatedBy: null,
  },
  {
    label: "Validation partenariale",
    icon: "handshake",
    valid: false,
    validatedAt: null,
    validatedBy: null,
  },
  {
    label: "Validation devis",
    icon: "file-lines",
    valid: false,
    validatedAt: null,
    validatedBy: null,
  },
  {
    label: "Validation CFA",
    icon: "graduation-cap",
    valid: false,
    validatedAt: null,
    validatedBy: null,
  },
  {
    label: "Envoi CERFA/Convention",
    icon: "paper-plane",
    valid: false,
    validatedAt: null,
    validatedBy: null,
  },
  {
    label: "Vérification documents",
    icon: "file-check",
    valid: false,
    validatedAt: null,
    validatedBy: null,
  },
  {
    label: "Envoi documents",
    icon: "paper-plane",
    valid: false,
    validatedAt: null,
    validatedBy: null,
  },
  {
    label: "Facturation",
    icon: "coins",
    valid: false,
    validatedAt: null,
    validatedBy: null,
  },
];
